import React from 'react'
import {Grid, Checkbox} from 'semantic-ui-react'
import Dropdown from '../../components/dropdown/dropdown'
import InputField from '../../components/inputfield/inputField'
import ReadOnlyField from '../../components/readonlyfield/readOnlyField'
import Button from '../../components/buttons/largebutton/largeButton'
import TreeNode from "../model/TreeNode";
import { Client } from './Client';
import NumberUtil from './NumberUtil';
import CurveGroup from "../model/CurveGroup";
import FileDisplay from "../../components/uploadedfilefield/uploadedFileField";
import isEmpty from "is-empty";

export default class ComponentParser {
    constructor(device, systemSettings, transformerData, growlRef, fileUploader) {
        this.device = device;
        this.systemSettings = systemSettings;
        this.transformerData = transformerData;
        this.growlRef = growlRef;
        this.fileUploader = fileUploader;
        this.numberUtil = new NumberUtil();
    }

    outPutTree = (components) => {
        const root = new TreeNode("root", 0);
        let currentNode = root;
        components.forEach(component => {
            const path = component.groupPath.split("/");
            path.forEach(name => {
                let child = currentNode.children.find(node => node.name === name);
                if (child !== undefined) {
                    currentNode = child;
                } else {
                    child = new TreeNode(name, currentNode.name, currentNode.level + 1);
                    currentNode.addChild(child);
                    currentNode = child;
                }
            });
            currentNode.component = component;
            currentNode = root;
        });

        return root;
    };

    renderComponents = (root, update) => {
        return this.parseTree(root, 0, update);
    };

    parseTree = (currentNode, fieldNum, update) => {
        if (currentNode.children.length === 0) {
            fieldNum = this.checkType(currentNode.component.type.toLowerCase()) ? fieldNum + 1 : 0;
            return (
                currentNode.component.groupPath.includes('global') ?
                    this.renderGlobals(currentNode.component, fieldNum, update)
                    :
                    this.renderComponent(currentNode.component, fieldNum, update)
            );
        } else {
            let components = [];
            currentNode.children.forEach(child => components = components.concat(this.parseTree(child, fieldNum, update)));
            return currentNode.level !== 2 ? components : components.concat(this.getDivider());
        }
    };

    checkType = (type) => {
        return (type === 'numeric_input' || type === 'read_only')
    };

    getDivider = () => {
        return (
            [<Grid.Column width={16}>
                <div className="horizonalDivider"/>
            </Grid.Column>]
        )
    };

    renderGlobals = (component, fieldNum, update) => {
        switch (component.type.toLowerCase()) {
            case 'select_one':
                return (

                    <Dropdown id={component.groupPath}
                              label={component.label}
                              options={component.options}
                              value={this.handleOptionalValue(component.value)}
                              onSelect={this.dropdownUpdate(component.groupPath, update)}
                    />

                );
            case 'numeric_input':
                return (

                    <InputField label={component.label}
                                type="number"
                                id={component.groupPath}
                                value={this.handleOptionalValue(component.value)}
                                min={this.handleOptionalValue(component.min)}
                                max={this.handleOptionalValue(component.max)}
                                suffix={component.suffix}
                                details={this.generateDetail(component)}
                                className={fieldNum % 2 === 0 ? "even" : "odd"}
                                changed={component.onChange ? this.numericUpdate(update) : this.genericUpdate(update)}
                    />

                );
            default:
                return null;
        }
    };

    renderComponent = (component, fieldNum, update) => {
        const isDisabled = this.isDisabled(component.dependsOn);
        const componentType = component.type.toLowerCase();

        switch (componentType) {
            case 'select_one':
                return (
                    <Grid.Column width={16}>
                        <Dropdown id={component.groupPath}
                                  label={component.label}
                                  options={component.options}
                                  onSelect={component.onChange ? this.dropdownUpdate(component.groupPath, update) : this.dropdownSimpleUpdate(component.groupPath, update)}
                                  value={this.handleOptionalValue(component.value)}
                                  disabled={isDisabled}
                        />
                    </Grid.Column>
                );
            case 'boolean':
                return (
                    <Grid.Column width={16}>
                        <Checkbox label={component.label} id={component.groupPath}
                                  checked={this.handleOptionalValue(component.value)}
                                  onChange={this.booleanUpdate(update)}
                                  disabled={isDisabled}
                        />
                    </Grid.Column>
                );
            case 'numeric_input':

                return (
                    <Grid.Column width={8}>
                        <InputField label={component.label}
                                    type="number"
                                    id={component.groupPath}
                                    value={this.handleOptionalValue(component.value)}
                                    min={isDisabled ? undefined : this.handleOptionalValue(component.min)}
                                    max={isDisabled ? undefined : this.handleOptionalValue(component.max)}
                                    suffix={component.suffix}
                                    details={this.generateDetail(component)}
                                    className={fieldNum % 2 === 0 ? "even" : "odd"}
                                    changed={component.onChange ? this.numericUpdate(update) : this.genericUpdate(update)}
                                    disabled={isDisabled}
                        />
                    </Grid.Column>
                );
            case 'read_only':
                return (
                    <Grid.Column width={8}>
                        <ReadOnlyField label={component.label}
                                       default={this.getReadOnlyValue(component.value)}
                                       suffix={component.suffix}
                                       id={component.groupPath}
                                       className={fieldNum % 2 === 0 ? "even" : "odd"}/>
                    </Grid.Column>
                );
            case 'file_upload':
                return (
                    <Grid.Column width={16}>
                        <Button isOutLine={true}
                                text="Import Curve Data"
                                iconName="uploadGray"
                                click={this.upload(update)}
                        />
                    </Grid.Column>
                );
            case 'text_input':
                return (
                    <Grid.Column width={16}>
                        <InputField label={component.label}
                                    type="text"
                                    id={component.groupPath}
                                    value={component.value.value}
                                    changed={this.genericUpdate(update)}
                        />
                    </Grid.Column>
                );
            case 'file_display':
                return(
                    <Grid.Column width={16}>
                        <FileDisplay
                            label={component.value.value}
                            onDelete={this.fileDelete(update, component.groupPath, component.name)}
                        />
                    </Grid.Column>
                );
            default:
                return;
        }
    };

    fileDelete = (update, path, name) => () => {
        const request = {device: this.device, systemSettings: this.systemSettings, target: name};
        Client.getNextComponents(request, this.handleComponentResponse(update, path, true))
    };

    upload = (update) => () => {
        this.fileUploader.current.upload(this.handleUpload(update), null, ".csv");
    };

    handleUpload = (update) => (e, fileName) => {
        console.log('FILE UPLOADED ->',e);
        const fileAsBinaryString = e.target.result;
        console.log('Read File');
        try {
            const fileData = {name : fileName, data : fileAsBinaryString.toLocaleString()};
            let devCpy = {...this.device};
            let components = [...devCpy.components];
            let uploadButton;
            let idx;
            for (let i = 0; i < components.length; i++){
                if (components[i].type.toLowerCase() === 'file_upload') {
                    uploadButton = {...components[i]};
                    idx = i;
                    break;
                }
            }
            uploadButton.value = {value : (fileData)};
            components[idx] = uploadButton;
            devCpy.components = components;
            const request = {device : devCpy, systemSettings : this.systemSettings};
            Client.getNextComponentsFromUpload(request, (response) => {
                console.log("RESPONSE -> ", response);
                if (response.status === 200) {
                    console.log('Data points response ->',response.data);
                    const handler = this.handleComponentResponse(update, uploadButton.groupPath, true);
                    handler(response.data)
                } else if (response.status === 400) {
                    console.log(response.data.errorMessage.description);
                    this.displayError("The file provided is invalid. Please upload a valid csv file.");
                } else {
                    this.displayError("Error Uploading File");
                }
            })
        } catch (e) {
            this.displayError("Error Uploading File");
        }
    };

    displayError = (message) => {
        let errorNotification = this.growlRef.current.addErrorNotif(message);
        let growl = this.growlRef.current;

        setTimeout(function () {
            growl.removeNotification(errorNotification);
        }, 5000);
    };

    isDisabled = (source) => {

        return source === undefined ? false : !this.findComponent(this.device, source).value.value;
    };

    isRangeValid = (component) => {
        const min = component?.value?.value;
        let isMinValid = true;
        if (min && component.min) {
            if(component.min.source){
                const minComponent = this.findComponent(this.device, component.min.source);

                const isSourceRangeValid = this.isRangeValid(minComponent);
                if (!isSourceRangeValid.min || !isSourceRangeValid.max) {
                    isMinValid = false;
                }
            }
            else{
                const minComponentValue = component.min?.value;
                if (minComponentValue) {
                    isMinValid = parseFloat(min) >= parseFloat(minComponentValue);
                }
            }
        }
        const max = component?.value?.value;
        let isMaxValid = true;
        if (max && component.max) {
            if(component.max.source){
                const maxComponent = this.findComponent(this.device, component.max.source);

                const isSourceRangeValid = this.isRangeValid(maxComponent);
                if (!isSourceRangeValid.min || !isSourceRangeValid.max) {
                    isMaxValid = false;
                }
            }
            else{
                const maxComponentValue = component.max?.value;
                if (maxComponentValue) {
                    isMaxValid = parseFloat(max) <= parseFloat(maxComponentValue);

                }
            }
        }
        return {min: isMinValid, max: isMaxValid};
    }

    booleanUpdate = (update) => (event) => {
        let component = this.findComponent(this.device, event.target.id);
        component.value.value = !component.value.value;
        let device = {...this.device};
        let groups = {...device.groups};
        let targetGroup = this.getBaseGroup(component.groupPath);
        let affectedGroup = {...groups[targetGroup]};
        let isCurveNamePresent = false;
        let isMinTripCurrentPresent = false;
        let isTimeOvercurrentEnabled = false;
        let isdt1Checked = false;

        const targetPath = component.groupPath;
        for (let idx in device.components) {
            let comp = device.components[idx];
            if (!component.value.value && comp.dependsOn === targetPath && comp.type.toLowerCase() === 'boolean') {
                comp.value.value = false
            }
            if(targetGroup === this.getBaseGroup(comp.groupPath) && comp.name === 'curveName' && comp.value!==undefined && comp.value.value !== undefined && comp.value.value !== ""){
                isCurveNamePresent = true;
            }
            if(targetGroup === this.getBaseGroup(comp.groupPath) && comp.name === 'curvedIsPosition' && comp.value!==undefined && comp.value.value !== undefined && comp.value.value !== ""){
                isMinTripCurrentPresent = true;
            }
            if(targetGroup === this.getBaseGroup(comp.groupPath) && comp.name === 'timeOverCurrent' && comp.value.value === true){
                isTimeOvercurrentEnabled = true;
            }
            if(targetGroup === this.getBaseGroup(comp.groupPath) && comp.name === 'dt1Checkbox' && comp.value.value === true){
                isdt1Checked = true;
            }
        }

        if((device.id === 37 || device.id === 38) && (component.name === 'dt1Checkbox' || component.name === 'timeOverCurrent')){
            if(isdt1Checked || ((device.id === 37 && isTimeOvercurrentEnabled && isCurveNamePresent) || (device.id === 38 && isTimeOvercurrentEnabled && isMinTripCurrentPresent))){
                affectedGroup.readyToDraw = true;
                groups[targetGroup] = affectedGroup;
            }else{
                affectedGroup.readyToDraw = false;
                groups[targetGroup] = affectedGroup;
            }
        }

        device.groups = groups;

        update(device);
    };

    genericUpdate = (update) => (event) => {


        let component = this.findComponent(this.device, event.target.id);
        let value = event.target.value;

        if (component.type.toLowerCase() === 'numeric_input') {
            if (!this.numberUtil.isValidInput(value)) return;

            value = this.numberUtil.processInput(event.target.value);
        }
        component.value.value = value;
        this.updateDependentFields(value, event.target.id);
        component.isDrawn = false;

        update(this.device);
    };

    updateDependentFields = (value, groupPath) => {
        this.device.components.forEach((component) => {
            if (
                component.value &&
                component.value.source &&
                component.value.source === groupPath &&
                (component.type.toLowerCase() === "numeric_input" ||
                    (component.type.toLowerCase() === "select_one" && component.options.some((option) => option.value === value)))
            ) {
                component.value = { value };
            }
        });
    }

    dropdownSimpleUpdate = (id, update) => (event, {value}) => {
        let device = {...this.device};
        device.components = [...device.components];
        let component = this.findComponent(device, id);

        if (component.value !== undefined && component.value.value === value) return;

        component.value = {value: value};

        update(device);
    };

    dropdownUpdate = (id, update) => (event, {value}) => {
        this.updateAndFetch(id, update, value);
    };

    numericUpdate = (update) => (event) => {

        console.log("*****Numeric Update executed")
        const input = event.target.value;
        if(!this.numberUtil.isValidInput(input)) return;

        if (input === '-') { // single '-' would cause error in BE, so we bypass the call
            this.genericUpdate(update)(event);
        } else {
            this.updateAndFetchNumeric(event.target.id, update, this.numberUtil.processInput(event.target.value));
        }
    };

    updateAndFetchNumeric = (id, update, value) => {
        let deviceCpy = {...this.device};
        let component = this.findComponent(deviceCpy, id);
        let targetGroup = this.getBaseGroup(id);

        deviceCpy.components = deviceCpy.components.filter((component, index) => {
            let group = this.getBaseGroup(component.groupPath);
            return (group === 'global' || group === targetGroup)
        });

        component.value = {value: value};

        let transformerComponents = this.transformerData === undefined? undefined : this.transformerData.components;
        let request = {target: component.name, device : deviceCpy, systemSettings : this.systemSettings, transformerData : transformerComponents};

        Client.getNextComponents(request, this.handleComponentResponse(update, id, false));
    };

    updateAndFetch = (id, update, value) => {
        let deviceCpy = {...this.device};
        let component = this.findComponent(deviceCpy, id);

        if (component.value !== undefined && component.value.value === value) return;

        const shouldFilter = component.type.toLowerCase() === 'select_one';

        let targetGroup = this.getBaseGroup(id);
        let targetIdx = this.findComponentIdx(this.device, id);

        if (shouldFilter) {
            deviceCpy.components = deviceCpy.components.filter((component, index) => {
                let group = this.getBaseGroup(component.groupPath);
                return (group === 'global' || group === targetGroup) && index <= targetIdx
            });
        }
        component.value = {value: value};

        let transformerComponents = this.transformerData === undefined? undefined : this.transformerData.components;
        let request = {device : deviceCpy, systemSettings : this.systemSettings, transformerData : transformerComponents};

        Client.getNextComponents(request, this.handleComponentResponse(update, id, shouldFilter));
    };

    handleComponentResponse = (update, path, shouldClear) => (data) => {
        let targetGroup = this.getBaseGroup(path);
        let device = {...this.device};
        let groups = {...Object.freeze(device.groups)};
        if (targetGroup !== 'global') {
            let affectedGroup = {...groups[targetGroup]};
            affectedGroup.readyToDraw = data.readyToDraw;
            groups[targetGroup] = Object.freeze(affectedGroup);
        }
        let components = [...device.components];
        let targetIdx = this.findComponentIdx(device, path);

        // clearing components below clicked
        if (shouldClear) {
            components = targetGroup !== 'global' ?
                device.components.filter((comp, index) => targetGroup !== this.getBaseGroup(comp.groupPath) || index <= targetIdx)
                :
                device.components.filter((_, index) => index <= targetIdx);
        }
        device.components = components;

        // merging new components with existing
        if(data.components !== undefined) {
            data.components.forEach(component => {
                let index = this.findComponentIdx(device, component.groupPath);
                if (component.type.toLowerCase() === 'select_one' && component.onChange === undefined) {
                    if((this.device.id === 37 && component.name=== 'curveName') ||(this.device.id === 38 && component.name=== 'curvedIsPosition')){
                        component.onChange = true;
                    }else {
                        component.onChange = !data.readyToDraw;
                    }
                }
                if (component.type.toLowerCase() === 'numeric_input') {
                    if (component.value.value !== undefined){
                        component.value.value = this.processNumericValue(component.value.value);
                    }
                    if (component.min !== undefined && component.min.value !== undefined) {
                        component.min.value = this.processNumericValue(component.min.value);
                    }
                    if (component.max !== undefined && component.max.value !== undefined) {
                        component.max.value = this.processNumericValue(component.max.value);
                    }
                }
                if (component.type.toLowerCase() === 'read_only' || component.type.toLowerCase() === 'hidden') {
                    if (component.value.value !== undefined && !isNaN(component.value.value)) {
                        component.value.value = this.processNumericValue(component.value.value);
                    }
                }

                if (index !== -1) {
                    components[index] = component;
                } else {
                    let lastPos = this.findLastComponentWithGroup(components, component.groupPath);
                    if (lastPos === -1) {
                        let groupName = this.getBaseGroup(component.groupPath);
                        components.push(component);
                        const name = device.name + "-" + groupName;
                        let newCurveGroup = new CurveGroup(name, data.readyToDraw, false);
                        if (groups[groupName] !== undefined) {
                            newCurveGroup.color = groups[groupName].color;
                        }
                        groups[groupName] = Object.freeze(newCurveGroup);
                    } else {
                        components.splice(lastPos + 1, 0, component);
                    }
                }
            });
            for (let key in groups) {
                let group = {...groups[key]};
                if (!isEmpty(group.series)) {
                    group.series = {}
                    groups[key] = group
                }
            }
        }

        device.groups = groups;
        device.isDrawn = false;
        update(device)
    };

    processNumericValue = (number) => {
        return parseFloat(parseFloat(number).toFixed(4)).toPrecision()
    };

    findLastComponentWithGroup = ([...components], path) => {
        let group = this.getBaseGroup(path);
        components.reverse();
        let comp = components.find(component => this.getBaseGroup(component.groupPath) === group);
        return comp === undefined ? -1 :
            components.reverse().findIndex(component => component.groupPath === comp.groupPath)
    };

    handleOptionalValue = (option) => {
        if (option !== undefined) {
            if (option.value !== undefined) {
                return option.value;
            } else {
                if (option.source === undefined) {
                    return option.default === undefined ? undefined : option.default;
                }
                let paths = option.source.split(",");

                while (paths.length > 0) {
                    let target = paths.splice(0, 1)[0];
                    let next = this.findComponent(this.device, target);

                    if (next === undefined || this.isDisabled(next.dependsOn)){
                        if (paths.length === 0) {
                            return option.default === undefined ? undefined : option.default;
                        }
                    } else {
                        return this.handleOptionalValue(next.value);
                    }

                }
            }
        } else {
            return undefined;
        }
    };

    findComponentIdx = (device, path) => {
        return device.components.findIndex(component => component.groupPath === path);
    };

    findComponent = (device, path) => {
        return device.components.find(component => component.groupPath === path);
    };

    generateDetail = (component) => {

        let result = component.detail !== undefined ? component.detail + " " : "";
        const min = this.handleOptionalValue(component.min);
        const max = this.handleOptionalValue(component.max);
        const disabled = this.isDisabled(component.dependsOn);
        const isRangeValid = this.isRangeValid(component)
        if (!disabled) {
            if (min !== undefined && max !== undefined) {
                result += "";
                if ((isRangeValid.min || !component.min?.source) && min !== undefined) {
                    result += parseFloat(min);
                } else if (!isRangeValid.min){
                    result += "??"
                }
                result += " <= ";

                if ((isRangeValid.max || !component.max?.source) && max !== undefined) {
                    result += parseFloat(max);
                } else if (!isRangeValid.max) {
                    result += "??";
                }
            } else if (min !== undefined) {
                result = ">= " + min;
            } else if (max !== undefined){
                result = "<= " + max;
            }
        }

        return result.trim();
    };

    getBaseGroup = (path) => {
        return path.split("/")[0];
    };

    getReadOnlyValue = (givenValue) => {
        if (givenValue.value === "primaryFullLoad") {
            return this.getFullLoadAmps();
        } else {
            return givenValue.value;
        }
    };

    getFullLoadAmps = () => {
        const amps = (Number(this.transformerData.rating) / (Number(this.systemSettings.voltage) * Math.sqrt(3.0)) * 1000.0) / 1000.0;
        return amps.toFixed(3).toString();
    };

}
