/**
 * Created by ameer.sami on 3/28/2018.
 */
import React from "react";
import { Input } from "semantic-ui-react";
import debounce from "lodash/debounce";

class inputField extends React.Component {

    state = {
        isError: this.props.isError ?? false,
        value: this.props.value,
    };
    debouncedOnChange = debounce(this.props.changed, 200);

    componentDidUpdate(prevProps, prevState) {
        this.debouncedOnChange = debounce(this.props.changed, 200);
        const newState = {};
        if (!this.props.isError && this.props.type === "number") {
            let isError = isNaN(this.props.value) || (this.props.noEmpty && this.state.value === "");

            isError = this.props.details !== undefined && this.props.details.includes("??");

            if (this.props.min !== undefined && !isError) {
                isError = parseFloat(this.props.value) < parseFloat(this.props.min);
            }
            if (this.props.max !== undefined && !isError) {
                isError = parseFloat(this.props.value) > parseFloat(this.props.max);
            }
            if (this.state.isError !== isError) {
                newState.isError = isError;
            }

        }

        if (prevProps.value !== this.props.value && prevState.value === this.state.value) {
            newState.value = this.props.value;
        }
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    onChange = (e) => {
        e.persist();
        var value = e.target.value;

        if(this.props.type === "number"){
            value = value.replace(/[^0-9.,-]/g, "");
            if (value.length > 0 && value[0] === "-") {
                value = "-" + value.slice(1).split("-").join("");
            } else {
                value = value.split("-").join("");
            }
            const dotSlices = value.split(/[.,]/g);
            if (dotSlices.length > 2) {
                value = [dotSlices.slice(0,2).join("."), ...dotSlices.slice(2)].join("");
            }
        }

        this.setState({ value: value });
        this.debouncedOnChange(e);
    };

    render() {
        return (
            <div className={"has-float-label " + this.props.className}>
                <Input
                    name={this.props.name}
                    id={this.props.id}
                    placeholder=" "
                    fluid
                    value={this.state.value}
                    error={this.state.isError}
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength || null}
                    onChange={this.onChange}
                    className={this.props.suffix ? "hasSuffix" : null}
                    onBlur={() => this.debouncedOnChange.flush()}
                />
                <label htmlFor={this.props.id}>{this.props.label || "I need a label"}</label>
                {this.props.suffix ? <span className="suffix">{this.props.suffix}</span> : null}
                {this.props.details ? <span className="helperText">{this.props.details}</span> : null}
            </div>
        );
    }
}

export default inputField;
