export default class TransformerData {

    constructor(transformerComponents, index) {
        this.connection = transformerComponents.find( (component) => { return component.name === 'curveName' } ).value.value;
        this.rating = transformerComponents.find( (component) => { return component.name === 'ratingKv' } ).value.value;
        this.secondary = transformerComponents.find( (component) => { return component.name === 'secondaryKV' } ).value.value;
        this.impedance = transformerComponents.find( (component) => { return component.name === 'impedance' } ).value.value;
        this.index = index;
        this.components = transformerComponents;
    }

}