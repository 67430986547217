import React from 'react'
import {Button} from 'semantic-ui-react'
import KebabMenu from '../kebabmenu/kebabMenu'
import ConfigurationCard from '../deviceconfigurationcard/deviceConfigurationCard'
import IconButton from '../buttons/iconbutton/iconButton'
import DeviceWarning from '../devicewarning/deviceWarning'
import Dropdown from '../dropdown/dropdown'
import CurveGroup from '../../ui/model/CurveGroup';
import ComponentParser from "../../ui/utils/ComponentParser";
import Highcharts from 'highcharts';
import { Client } from '../../ui/utils/Client';
import Device from "../../ui/model/Device";
import XsptUpload from '../../components/xsptupload/XsptUpload';
import * as numericInputUtil from '../../ui/utils/InputValidationUtil';

let transformerId = 11;
let userProvidedId = 9;

const deviceConfigurationPanel = (props) => {
    let device = props.devices[props.index];
    return (
        <div className={"deviceConfigPanelContainer " + props.devicePanelOpen}>
            <div className="deviceConfigurationPanel">
                <div className="headerButtons">
                    <IconButton iconName="leftArrow" onClick={closePanel(props)} toolTip="Close Device Panel"/>
                    <KebabMenu side="left" toolTip="Device Options" dropOptions={getDeviceOptions(props, device)}/>

                </div>

                <Dropdown id={1}
                          label="Device Type"
                          options={props.deviceOptions}
                          value={device === undefined ? null : device.dropDownValue}
                          onSelect={onDeviceSelect(props)}
                />
                {device !== undefined
                    ?
                    renderDevicePanel(device, props, props.devices)
                    :
                    null
                }

                {
                    props.xsptUploadDialogOpen
                        ?
                        <XsptUpload isOpen={props.xsptUploadDialogOpen} growl={props.growl} componentUpdater={xsptUploadUpdater(device, props)} dialogHandler={xsptDialogHandler(props)} fileUploader={props.fileUploader}/>
                        :
                        null
                }


            </div>
        </div>
    )
};

const getDeviceOptions = (props, device) => {

    let deviceOptions = [
                {text: "Delete Current Device", value: "delete", iconName: "deleteRed", onClick: onKebabMenuSelect(props, device)}
                ];

    if(device !== undefined) {

        let readyToPlot = !checkIfDisabled(device.groups);

        if(readyToPlot && device.id !== userProvidedId) {
            deviceOptions.splice(0, 0, {text: "Download Curve Points", value: "download", iconName: "downloadGray", onClick: onKebabMenuSelect(props,device)});
        }

        if(device.hasSetPoints) {
            deviceOptions.splice(0, 0, {text: "Upload XSPT Setpoints", value: "uploadXSPT", iconName: "uploadGray", onClick: onKebabMenuSelect(props,device)});

            if(readyToPlot) {
                deviceOptions.splice(1, 0, {text: "Download XSPT Setpoints", value: "downloadXSPT", iconName: "downloadGray", onClick: onKebabMenuSelect(props,device)} )
            }
        }
    }

    return deviceOptions
};

const closePanel = (props) => () => {
    props.update([{name: "devicePanelOpen", value: !props.devicePanelOpen},{name: "currentDevice", value: null}]);
    setTimeout(function(){Highcharts.charts.forEach(chart => chart?.reflow())}, 1000);
};

const onKebabMenuSelect = (props, device) => (event,data) => {
    switch (data.value) {
        case "download":
            let request = {};
            request.systemSettings = props.systemSettings;
            request.device = device;
            let outOfRangeFields = numericInputUtil.areAllInputsInRange(null, device);
            if (props.transformerData !== undefined && props.transformerData.index < props.index) {
                request.transformerData = props.transformerData.components;
            }

            if(outOfRangeFields.length === 0){
                Client.getCsvExport(request);
            }
            else{
                generalErrorGrowl(props.growl, "Unable to download data due to invalid device configuration");
            }
            
            break;
        case "downloadXSPT":
            Client.getXsptDownloadExport(device,
                (error) => {
                    console.log("Unable to export XSPT for device: " + device.name, error);
                    generalErrorGrowl(props.growl, "Unable to export XSPT at this time. Please try again later");
                } );
            break;

        case "uploadXSPT":
            props.update(
                [
                    {name: "xsptUploadDialogOpen", value: true},
                ]
            );
            break;
        case "delete":
            props.update(
                [
                    {name: "dialogHeaderText", value:"Delete " + (device.name ? device.name : "Device") + " Confirmation"},
                    {name: "dialogContent", value:["Are you sure you would like to delete " + (device.name ? "the " + device.name : "this device") + "?" + getAdditionalDeleteInfo(device)]},
                    {name: "confirmLabel", value:"Delete"},
                    {name: "cancelLabel", value:"Cancel"},
                    {name: "actionType", value:"deleteDevice"},
                    {name: "confirmDialogOpen", value:true},
                    {name: "dialogSize", value:"small"}
                ]
            );
            break;
        default:
            break;
    }
};


const xsptUploadUpdater = (device, props) => (data) => {

    console.log("XSPT - Received payload and updating UI");

    let deviceCopy = {...device};

    deviceCopy.components = data.components;
    deviceCopy.groups = {};

    setupCurveGroups(deviceCopy, data, deviceCopy.systemSettings, props.index);

    console.log("XSPT - Determining right readyToDraw for each group");

    let groups = {...Object.freeze(deviceCopy.groups)};

    const readyToDrawGroups = deviceCopy.components
                                    .filter(component => component.name === 'deviceType')
                                    .map(component => component.groupPath.split("/")[0]);

    const groupKeys = Object.keys(groups);
    const groupValues = Object.values(groups);

    for (let i = 0; i < groupKeys.length; i++) {

        let group = groupValues[i];

        let groupKey = groupKeys[i];
        let readyToDraw = false;

        if(readyToDrawGroups.includes(groupKey)) {
            readyToDraw = true;
        }

        groups[groupKey] = Object.freeze(new CurveGroup(group.name, readyToDraw, true));
    }

    deviceCopy.groups = groups;
    deviceUpdate(props)(deviceCopy)

    console.log("XSPT - UI update completed");
};

const xsptDialogHandler = (props) => (event, actionType) => {
    props.update(
        [
            {name: "xsptUploadDialogOpen", value: false},
        ]
    );

};


const getAdditionalDeleteInfo = (device) => {
    return (device.id !== transformerId)? "" : " Any subsequent low-voltage devices will be deleted as well."
};

const getTransformerWarning = () => {
  return <DeviceWarning iconName="info" message="The TCC curves of any Devices added after the Transformer 
  are scaled down by the Primary-to-Secondary Voltage ratio of the Transformer. To compare Device TCC curves 
  without any changes due to a Transformer, add the Device before the Transformer."/>
};

const onDeviceSelect = (props) => (e, {value}) => {
    if (props.devices[props.index].name === value.name) return; // bail if no change in selection
    let devices = [...Object.freeze(props.devices)];
    let device =  new Device(value.name, value.id, value.hasSetPoints);
    let systemSettings = props.systemSettings;
    let transformerComponents = props.transformerData === undefined? undefined : props.transformerData.components;
    device.dropDownValue = value;
    device.name = value.name;
    device.id = value.id;
    device.hasSetPoints = value.hasSetPoints;
    
    let request = {device: device, systemSettings: systemSettings, transformerData: transformerComponents};
    Client.getNextComponents(request, (data) => {
        data.components.forEach(component =>{
            if (component.type.toLowerCase() === 'select_one' && component.onChange === undefined) {
                component.onChange = !data.readyToDraw;
            }
        });
        device.components = data.components;
        device.hasMultipleCurves = data.hasMultipleCurves;
        setupCurveGroups(device, data, props.systemSettings, props.growl, props.fileUploader);
        devices[props.index] = Object.freeze(device);

        props.update([{name: "devices", value: devices}])
    })
};

const setupCurveGroups = (device, data, systemSettings, growl, fileUploader) => {
    let parser = new ComponentParser(device, systemSettings, undefined, growl, fileUploader);
    let componentRoot = parser.outPutTree(data.components);
    componentRoot.children.forEach(child => {
        let name = device.name;
        device.groups[child.name] = Object.freeze(new CurveGroup(device.hasMultipleCurves ? name + " - " + child.name : name, data.readyToDraw, true));
    });
};

const toggle = (props) => (index, valName) => () => {
    let devices = [...Object.freeze(props.devices)];
    let device = {...devices[props.index]};
    let groups = {...Object.freeze(device.groups)};
    let targetGroup = {...groups[index]};
    targetGroup[valName] = !targetGroup[valName];
    groups[index] = Object.freeze(targetGroup);
    device.groups = groups;
    devices[props.index] = device;
    props.update([{name: "devices", value: devices}])
};

const colorUpdate = (props, groupKey) => (color) => {
    let devices = [...Object.freeze(props.devices)];
    let rgb = color.rgb;
    let device = {...devices[props.index]};
    let groups = {...Object.freeze(device.groups)};
    let targetGroup = {...groups[groupKey]};
    targetGroup.color = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    groups[groupKey] = targetGroup;
    device.groups = groups;
    devices[props.index] = device;
    props.update([{name: "devices", value: devices}])
};

const renderDevicePanel = (device, props, devices) => {
    let parser = new ComponentParser(device, props.systemSettings, props.transformerData, props.growl, props.fileUploader);

    let root = parser.outPutTree(device.components);
    let result = [];

    if (device.id === transformerId) {
      result = result.concat(getTransformerWarning())
    }

    result = result.concat(root.children.filter(child => child.name === 'global').map(section => {
        return parser.renderComponents(section, deviceUpdate(props))
    }));

    result = result.concat(
        root.children.filter(child => child.name !== 'global').map((child) =>
            <ConfigurationCard name={child.name}
                               device={device}
                               systemSettings={props.systemSettings}
                               group={device.groups[child.name]}
                               colorUpdate={colorUpdate(props, child.name)}
                               toggle={toggle(props)}
                               isCollapsable={device.hasMultipleCurves}
                               isDeletable={false}
                               graphColor={child.color}
                               componentRoot={child}
                               index={child.name}
                               update={deviceUpdate(props)}
                               devices={devices}
                               transformerData={props.transformerData}
                               growl={props.growl}
                               fileUploader={props.fileUploader}
            />
        )
    );

    // Add setpoint button, may implement in future release
    // if (false) {
    //     result.push(<LargeButton isOutLine={true} iconName="addGray" text="Add Setpoint Group"/>)
    // }

    result.push(<Button content="Plot" className="plotButton" onClick={props.plot(props.index)} disabled={checkIfDisabled(device.groups)}/>);

    return result;
};

const checkIfDisabled = (groups) => {
    return (Object.values(groups).find(group => group.readyToDraw === true) === undefined);
};

const deviceUpdate = (props) => (device) => {
    let devices = [...Object.freeze(props.devices)];
    devices[props.index] = device;
    props.update([{name: "devices", value: devices}]);
};

const generalErrorGrowl = (growl, text) => {

    let errorNotification = growl.current.addErrorNotif(text);
    let removeNotification = growl.current.removeNotification;

    setTimeout(function () {
        removeNotification(errorNotification);
    }, 7000);
};

export default deviceConfigurationPanel