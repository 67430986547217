import React, {Component} from 'react';
import logo from '../../ui/resources/SC_COLOR_LOGO.svg';
import Button from '../buttons/largebutton/largeButton';

export default class Error404Page extends Component {

    render() {
            return (
                <div style={this.styles.container}>
                    <div className="navBar">
                        <div className="logoSection">
                            <img src={logo} alt=""/>
                            <span>Coordinaide<sup>TM</sup></span>
                        </div>
                    </div>
                    <div style={this.styles.contentContainer}>
                        <div style={this.styles.textBody}>
                            <h1 style={this.styles.oopsText}>Oops!</h1>
                            <h3 style={this.styles.secondaryText}>Looks like something went wrong</h3>
                            <Button text={"Reload"} click={() => window.location.replace("/")} style={this.styles.reloadButton}/>
                        </div>
                    </div>
                </div>
                );
    }

    styles = {
        container: {
            height: "100%",
            width: "100%"
        },
        contentContainer: {
            // eslint-disable-next-line
            display: "-webkit-box",
            // eslint-disable-next-line
            display: "-moz-box",
            // eslint-disable-next-line
            display: "-ms-flexbox",
            // eslint-disable-next-line
            display: "-webkit-flex",
            // eslint-disable-next-line
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80%"
        },
        textBody: {
            padding: "40px",
            width: "auto"
        },
        oopsText: {
            fontSize: "4rem",
            color: "#257C59",
            textAlign: "center"
        },
        secondaryText: {
            fontSize: "25px",
            textAlign: "center",
            marginTop: "0px"
        },
        reloadButton: {
            width: "auto",
            marginTop: "20px",
            margin: "auto"
        }
    }
}