export default class TreeNode {
    constructor(name, parent, level){
        this.name = name;
        this.parent = parent;
        this.level = level;
        this.children = [];
    }

    addChild = (child) => {
        this.children = this.children.concat(child);
    }
}