import React from 'react'
import { Button } from 'semantic-ui-react'
import Icon from '../../icon/icon'

const iconButton = (props) => {
    return(
        <Button icon className="iconOnlyButton" onClick={props.onClick} title={props.toolTip}>
            <Icon name={props.iconName}/>
        </Button>
    )
}

export default iconButton