import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import IconButton from '../buttons/iconbutton/iconButton'

const Dialog=(props) => {


    const close = (event) => {
        event.preventDefault();
        props.update(props.actionType, false);
    };

    const confirm = (event) => {
        event.preventDefault();
        props.update(props.actionType, true);
    }

    return(
        <Modal size={props.size}
               dimmer={props.isDimming ? "blurring" : ""}
               open={props.isOpened}
               onClose={close}
               closeOnEscape={props.closeOnEscape}
               closeOnDimmerClick={false}
               className={props.className + " dialog " + (props.actionType.toLowerCase().includes("delete") ? "warning" : "" )}>
            <Modal.Header>{props.headerText} {props.isClosable ? <IconButton iconName="close" onClick={close}/> : null}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div>
                        {props.content && Object.values(props.content).map((element, index) => {
                            return <p key={index}>{element}</p>;
                        })}
                    </div>
                    {props.children}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {
                    props.cancelLabel ? <Button onClick={close} content={props.cancelLabel} className="cancelButton"/> : null
                }

                {
                    props.confirmLabel ? <Button autoFocus content={props.confirmLabel} disabled={props.disableConfirm || false} onClick={confirm} className={"deleteButton " + props.ctaClass + " " + (props.actionType.toLowerCase().includes("delete") ? "" : "confirmButton" )}/> : null
                }

            </Modal.Actions>
        </Modal>
    )
};

export default Dialog
