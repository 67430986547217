import React, {Component} from 'react';
import Highcharts from 'highcharts';
import Export from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import * as util from '../../ui/utils/GraphUtil';

export default class Graph extends Component {

    componentDidMount() {
        Export(Highcharts);
        More(Highcharts);
        this.chart = new Highcharts['chart'](
            'chart',
            util.generateStockChart(this.props.plotLine)
        );
    }

    componentWillUnmount() {
        this.chart.destroy();
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.checkSeries(nextProps.series, this.props.series) ||
            nextProps.filled !== this.props.filled ||
            nextProps.plotLine !== this.props.plotLine ||
            nextProps.yAxis !== this.props.yAxis ||
            nextProps.frequency !== this.props.frequency
        )
    }

    checkSeries = (newSeries, oldSeries) => {
        if (newSeries.length !== oldSeries.length) {
            return true;
        } else {
            return (newSeries.find((serie, index) => {
                return (
                    (serie.visible !== oldSeries[index].visible) ||
                    (serie.color !== oldSeries[index].color) ||
                    (serie.series !== oldSeries[index].series)
                )
            }) !== undefined)
        }
    };

    componentDidUpdate() {
        this.chart.destroy();
        this.chart = new Highcharts["chart"](
            "chart",
            !this.checkIfCurvesExist(this.props.series)
                ? util.generateStockChart(this.props.plotLine, this.props.yAxis, this.props.frequency)
                : util.generatePopulatedChart(
                      this.props.series,
                      this.props.plotLine,
                      this.props.filled,
                      this.props.yAxis,
                      this.props.frequency,
                      this.props.updater
                  )
        );
    }

    checkIfCurvesExist = (series) => {
        let isEmptyData=true;
        let groups = Object.values(series);
        groups.forEach(s=>{
            Object.values(s.series).forEach(value => {
                if(value.data!==""){isEmptyData=false}
            });
        });
        return groups.length > 0 && (groups.find(serie => serie.visible === true) !== undefined) && !isEmptyData;
    };

    render() {
        return (
            <div id='chart'/>
        )
    }
};