import React, {Component} from 'react';
import 'core-js/stable';
import NavBar from '../../components/navbar/navBar';
import DeviceChain from '../../components/devicechain/deviceChain';
import DeviceConfigurationPanel from '../../components/deviceconfigurationpanel/deviceConfigurationPanel';
import TccGraph from '../../components/tccgraph/tccGraph';
import Growl from '../../components/growl/growl';
import SystemSettings from '../model/SystemSettings'
import { Client } from "../utils/Client";
import Serie from '../model/Serie';
import Dialog from '../../components/dialog/dialog'
import FileUploader from '../../components/fileUploader/fileuploader';
import isEmpty from 'is-empty';
import Highcharts from "highcharts";
import TransformerData from '../utils/TransformerData';
import * as numericInputUtil from '../utils/InputValidationUtil';
import ComponentParser from '../../ui/utils/ComponentParser';
import * as graphUtil from '../../ui/utils/GraphUtil';

export default class Coordinaide extends Component {
    state = {
        systemSettings: new SystemSettings(),
        devicePanelOpen: "init",
        currentDevice: 0,
        deviceOptions: [],
        devices: [],
        confirmDialogOpen: false,
        actionType: "",
        confirmLabel: "",
        cancelLabel: "Cancel",
        dialogContent: "",
        dialogHeaderText: "",
        dialogSize: "small",
        xsptUploadDialogOpen: false,
        hasAcceptedTerms: sessionStorage.getItem('hasAcceptedTerms'),
        transformerData: undefined
    };
    transformerId = 11;
    growlRef = React.createRef();
    graphRef = React.createRef();
    uploaderRef = React.createRef();
    navBarRef = React.createRef();

    getYear() {
        return new Date().getFullYear();
    }

    componentDidMount() {
        Client.initialize(this.growlRef.current);
    }

    render() {
        return (
            <div className="App">
                <Growl ref={this.growlRef}/>
                <NavBar ref={this.navBarRef} settings={this.state.systemSettings} update={this.onNavBarUpdate} load={this.genericUpdate}
                        projectState={this.state} delete={this.genericUpdate} growl={this.growlRef}
                        compileSummaryExport={this.compileSummaryExport}
                        initialUpload={this.uploadProject}/>

                <div className="body">
                    <DeviceChain devices={this.state.devices} update={this.genericUpdate} recalc={this.recalcOnReorder}
                                 current={this.state.currentDevice} uploadExistingProject={this.uploadProject}
                                 growl={this.growlRef} isSystemSettingsValid={this.isSystemSettingsValid}/>
                    <DeviceConfigurationPanel devices={this.state.devices}
                                              systemSettings={this.state.systemSettings}
                                              transformerData={this.state.transformerData}
                                              deviceOptions={this.state.deviceOptions}
                                              devicePanelOpen={this.state.devicePanelOpen}
                                              xsptUploadDialogOpen={this.state.xsptUploadDialogOpen}
                                              update={this.genericUpdate}
                                              index={this.state.currentDevice}
                                              plot={this.plotDeviceHandler}
                                              growl={this.growlRef}
                                              fileUploader={this.uploaderRef}
                    />
                    <TccGraph systemFault={this.state.systemSettings.systemFault}
                              frequency={this.state.systemSettings.frequency}
                              series={this.flattenGroups()}
                              ref={this.graphRef}
                              updater={this.deviceChangeHandler}
                    />

                    <Dialog key="error-dialog" 
                            headerText={this.state.dialogHeaderText}
                            content={this.state.dialogContent}
                            confirmLabel={this.state.confirmLabel}
                            cancelLabel={this.state.cancelLabel}
                            isOpened={this.state.confirmDialogOpen}
                            actionType={this.state.actionType}
                            update={this.dialogHandler}
                            size={this.state.dialogSize}
                            isClosable={true}
                            isDimming={true}
                            closeOnEscape/>

                    <Dialog key="terms-and-conditions-dialog" 
                            headerText={"Terms and Conditions Acknowledgment"}
                            isInputError={this.state.isInputError}
                            content={['S&C does not accept responsibility for, nor warrant the proper application of non-S&C protective devices, or the accuracy of their respective time-current characteristic curves. Moreover, S&C is not responsible for any errors, mistakes, or miscalculations that may occur through use of the Coordinaide™ application. Results should be reviewed and approved by a consultant or end user who is familiar with the principles of selective coordination and system protection.', '', '', 'This program is the property of S&C Electric Company, Chicago, IL and may not be sold nor may any part of the program source code be used such as to produce a commercial, marketable product.']}
                            confirmLabel={"Accept"}
                            cancelLabel="Cancel"
                            isOpened={!this.state.hasAcceptedTerms}
                            isClosable={false}
                            actionType={"acceptTerms"}
                            update={this.dialogHandler}
                            size={"large"}
                            isDimming={true}
                            className="termsAndConditions"
                            ctaClass={"confirmButton"}
                            closeOnEscape>
                        <br/>
                        <p>We use cookies to give you the best online experience. By using our website, you agree to our
                            use of cookies in accordance with our privacy policy. <a
                                href="https://www.sandc.com/en/privacy-statement/">Learn more here.</a></p>
                    </Dialog>
                    <FileUploader ref={this.uploaderRef}/>
                </div>
                <span className="copyRightText">© 2000-{this.getYear()} S&C Electric Company | All rights reserved</span>
            </div>
        );
    }

    deviceChangeHandler = (index) => {
        this.setState({
            devicePanelOpen: true,
            currentDevice: index
        }, () => Highcharts.charts.forEach(chart => chart?.reflow()))
    }

    dialogHandler = (actionType, confirmed) => {
        if (confirmed) {
            switch (actionType) {
                case "deleteDevice":
                    this.deleteDevice();
                    break;
                case "deleteProject":
                    this.deleteProject();
                    break;
                case "uploadProject":
                    this.uploadProject();
                    this.setState({
                        confirmDialogOpen: false
                    });
                    break;
                case "acceptTerms":
                    this.acceptTerms();
                    break;
                default:
                    this.setState({
                        confirmDialogOpen: false
                    });
                    break;
            }
        } else {
            if (actionType === "acceptTerms") {
                this.redirectToSC();
            }
            this.setState({
                confirmDialogOpen: false,
                actionType: ""
            });
        }
    };

    deleteDevice = () => {
        const device = this.state.devices[this.state.currentDevice];
        if (device.id !== '' && device.id === this.transformerId) {
            this.setState({transformerData: undefined});
            this.removeTransformerOnlyDevices(this.state.currentDevice);
            this.reGraphOnTransformerDeletion(this.state.currentDevice);
        } else {
            let devices = this.state.devices.filter((_, i) => i !== this.state.currentDevice);
            this.setState({
                devices: devices,
                currentDevice: this.state.currentDevice !== 0 ? this.state.currentDevice - 1 : 0,
                devicePanelOpen: this.state.currentDevice !== 0,
                confirmDialogOpen: false,
                actionType: ""
            });
        }
        setTimeout(function () {
            Highcharts.charts.forEach(chart => chart?.reflow())
        }, 1000);
    };

    uploadProject = () => {

        const uploadHandler = (e) => {

            let updateState = this.uploadUpdatedState;
            let createGrowl = this.displayUploadingGrowl;
            let errorUploadingGrowl = this.errorUploadingGrowl;

            console.log(e);
            const fileAsBinaryString = e.target.result;
            createGrowl();
            try {
                Client.verifyState(JSON.parse(fileAsBinaryString.toString()), (response) => {
                    if (response.status === 202) {
                        const uploadJson = JSON.parse(fileAsBinaryString.toString());
                        const projectSettings = JSON.parse(uploadJson.projectSettings);
                        updateState(projectSettings)
                    } else if (response.status === 400) {
                        errorUploadingGrowl("Error Uploading Project File, File may be corrupt");
                    } else {
                        errorUploadingGrowl("Unexpected Error Uploading Project File");
                    }
                })
            } catch (e) {
                if (e instanceof SyntaxError) {
                    errorUploadingGrowl("Unable to read file, please make sure file formatting is correct");
                } else {
                    errorUploadingGrowl("Unexpected Error Uploading Project File");
                }
            } finally {
                this.setState({actionType: ""});
            }

        };

        const errorHandler = () => {
            console.log('file reading was aborted')
        }

        this.uploaderRef.current.upload(uploadHandler, errorHandler, ".coor");
    };

    uploadUpdatedState = (projectSettings) => {
        this.setState({
            systemSettings: projectSettings.systemSettings,
            devicePanelOpen: projectSettings.devicePanelOpen,
            currentDevice: projectSettings.currentDevice,
            deviceOptions: projectSettings.deviceOptions,
            devices: projectSettings.devices,
            transformerData: projectSettings.transformerData
        });

        this.doneUploadingGrowl();
        setTimeout(function () {
            Highcharts.charts.forEach(chart => chart?.reflow())
        }, 1000);
    };

    acceptTerms = () => {
        sessionStorage.setItem('hasAcceptedTerms', 'true');
        this.setState({
            hasAcceptedTerms: true
        });
    };

    redirectToSC = () => {
        window.location.href = "https://www.sandc.com/";
    };

    compileSummaryExport = () => {

        let tables = [];
        let devices = [...Object.freeze(this.state.devices)];

        devices.forEach((device, index) => {
            if (device.isDrawn) {
                let parser = new ComponentParser(device, this.state.systemSettings, this.state.transformerData, this.uploaderRef.current);
                let componentValues = {};

                device.components.forEach((component) => {
                    if (this.isComponentWhiteListed(component)) {
                        let groupKey = this.getBaseGroup(component.groupPath);
                        let componentName = component.label;

                        if (componentValues[groupKey] === undefined) {
                            componentValues[groupKey] = [];
                        }

                        let componentValue =
                            component.type === "SELECT_ONE" ? this.extractDropdownSelectionLabel(component) : parser.handleOptionalValue(component.value);

                        if (componentValue !== undefined) {
                            componentValues[groupKey].push(["", componentName, componentValue]);
                        }
                    }
                });

                let deviceInfo = {};
                let sections = [];

                let groups = { ...Object.freeze(device.groups) };

                Object.keys(groups).forEach((groupKey) => {
                    const globalKey = "global";
                    let group = { ...device.groups[groupKey] };

                    if ((!group.visible || isEmpty(group.series) || !this.checkAtLeastOneSerieHasData(group.series)) && groupKey !== globalKey) {
                        return;
                    }

                    let section = {};

                    section.title =
                        groupKey === globalKey
                            ? device.name.replace(/®/gi, "&#xae") + " - Global Settings"
                            : group.name.replace(/®/gi, "&#xae").replace(/\${.*}/g, "-");
                    section.color = group.color;
                    section.rows = componentValues[groupKey];

                    if (section.title.split(" - ").length > 1 && groupKey !== globalKey) {
                        section.title = section.title.split(" - ")[1];
                    }

                    sections.push(section);
                });

                if (sections.length !== 0) {
                    deviceInfo.title = index + 1 + "." + device.name.replace(/®/gi, "&#xae");
                    deviceInfo.columns = [
                        {
                            text: "Curve",
                            style: [
                                {
                                    bold: true,
                                },
                            ],
                        },
                        {
                            text: "Property",
                            style: [
                                {
                                    bold: true,
                                },
                            ],
                        },
                        {
                            text: "Value",
                            style: [
                                {
                                    bold: true,
                                },
                            ],
                        },
                    ];
                    deviceInfo.sections = sections;

                    tables.push(deviceInfo);
                }
            }
        });

        const dataExport = {};
        if (tables.length > 0) {
            tables.unshift(this.getSystemSettingsTable());
            let flattenedGroups = this.flattenGroups();
            let chartOptions = graphUtil.generatePopulatedChart(flattenedGroups, this.state.systemSettings.systemFault, this.graphRef.current.state.filled, this.graphRef.current.state.cycles, this.state.systemSettings.frequency);
            let annotations = graphUtil.generateAnnotations(flattenedGroups, true)
    
            chartOptions.xAxis.min = 1;
            chartOptions.xAxis.max = 100000;
            chartOptions.xAxis.gridLineColor = '#D3D3D3';
            chartOptions.xAxis.gridLineWidth = 1;
            chartOptions.xAxis.minorGridLineColor = '#D3D3D3';
            chartOptions.yAxis.min = 0.01;
            chartOptions.yAxis.max = 1000;
            chartOptions.yAxis.gridLineColor = '#D3D3D3';
            chartOptions.yAxis.minorGridLineColor = '#D3D3D3';
            chartOptions.annotations = [annotations];
            dataExport.chartOptions = chartOptions;
            dataExport.tables = tables;
            dataExport.yAxisMultiplier = this.graphRef.current.state.cycles ? this.state.systemSettings.frequency : 1;
        }


        return dataExport;
    };

    extractDropdownSelectionLabel = (component) => {
        for (const option of component.options) {
            if (component.value !== undefined && option.value === component.value.value) return option.text;
        }
        return "Value Not Found";
    };

    isComponentWhiteListed = (component) => {
        return component.type === 'NUMERIC_INPUT' || component.type === 'SELECT_ONE' || component.type === 'BOOLEAN' || component.type === 'READ_ONLY';
    };

    getSystemSettingsTable = (tables) => {
        let black = "rgba(0, 0, 0, 1)";

        return({
            title: "System Settings",
            columns: [
                {
                    text: "Frequency",
                    style: [
                        {
                            bold: true
                        }
                    ]
                },
                {
                    text: "Voltage",
                    style: [
                        {
                            bold: true
                        }
                    ]
                },
                {
                    text: "Available Fault Current",
                    style: [
                        {
                            bold: true
                        }
                    ]
                }
            ],
            sections: [
                {
                    title: this.state.systemSettings.frequency + " Hz",
                    color: black,
                    rows: [["placeholder", this.state.systemSettings.voltage + " kV", this.state.systemSettings.systemFault + " A"]]
                }
            ]
        });
    };

    isSystemSettingsValid = () => {
        const outOfRangeFields = this.navBarRef.current.areAllInputsInRange();
        if (outOfRangeFields.length > 0) {
            this.showInvalidOrOutOfRangeValueError(outOfRangeFields);
            return false;
        }
        return true;
    }

    showInvalidOrOutOfRangeValueError = (outOfRangeFields) => {
        const dialogContent = [];
        dialogContent.push("The input value(s) for the following field(s) are out of range or empty:");
        for (const field of outOfRangeFields) dialogContent.push("* " + field);
        let updateList = [
            { name: "dialogHeaderText", value: "Error" },
            { name: "dialogContent", value: dialogContent },
            { name: "confirmLabel", value: "Okay" },
            { name: "cancelLabel", value: "" },
            { name: "confirmDialogOpen", value: true },
            { name: "dialogSize", value: "small" },
        ];
        this.genericUpdate(updateList);
    }

    plotDeviceHandler = (index) => () => {
        let device = {...this.state.devices[index]};
        let outOfRangeFields = numericInputUtil.areAllInputsInRange(index, device);
        outOfRangeFields.unshift(...this.navBarRef.current.areAllInputsInRange());
        if (outOfRangeFields.length === 0) {
            let request = {};
            request.systemSettings = this.state.systemSettings;
            request.device = device;

            if (this.state.transformerData !== undefined && this.state.transformerData.index < index) {
                request.transformerData = this.state.transformerData.components;
            }
            Client.getCalculatedCurves(request, (data) => {
                let devices = [...Object.freeze(this.state.devices)];
                let device = {...devices[index]};
                device.isDrawn = true;
                let groups = {...Object.freeze(device.groups)};
                this.clearSeries(groups);
                data.curves.forEach(curve => {
                    let groupName = this.getBaseGroup(curve.name);
                    let group = {...groups[groupName]};
                    let series = {...group.series};
                    series[curve.name] = new Serie(curve.curve, curve.type);
                    group.series = series;
                    groups[groupName] = group;
                });

                device.groups = groups;
                devices[index] = device;
                let updateList = [{name: "devices", value: devices}];

                if (device.id === this.transformerId) {
                    updateList.push({name: "transformerData", value: new TransformerData(device.components, index)});
                }

                this.genericUpdate(updateList)
            })
        } else {
            this.showInvalidOrOutOfRangeValueError(outOfRangeFields)
        }
    };

    displayUploadingGrowl = () => {
        this.loadingNoti = this.growlRef.current.addLoadingNoti("Uploading Project");
    };

    doneUploadingGrowl = () => {
        this.growlRef.current.doneLoadingNotif(this.loadingNoti, "Project Upload Complete");
        let loadingNoti = this.loadingNoti;
        let removeNoti = this.growlRef.current.removeNotification;
        setTimeout(function () {
            removeNoti(loadingNoti);
        }, 2000);
    };

    errorUploadingGrowl = (text) => {
        this.growlRef.current.errorLoadingNotif(this.loadingNoti, text);
        let errorGrowl = this.loadingNoti;
        let removeNoti = this.growlRef.current.removeNotification;
        setTimeout(function () {
            removeNoti(errorGrowl);
        }, 5000);
    };

    getBaseGroup = (path) => {
        return path.split("/")[0];
    };

    flattenGroups = () => {
        let result = [];
        this.state.devices.filter(device => device.isDrawn).forEach((device, index) => {
            for (let key in device.groups) {
                let group = {...device.groups[key]};
                if (!isEmpty(group.series) && this.checkAtLeastOneSerieHasData(group.series)) {
                    group.name = (index + 1) + "." + group.name;
                    result.push(group);
                }
            }
        });
        return result;
    };

    checkAtLeastOneSerieHasData = (series) => {
        for (let key in series) {
            if (series[key].data !== undefined && series[key].data.length > 0) {
                return true;
            }
        }
        return false;
    };

    genericUpdate = (valuePairList) => {
        this.setState((prevState) => {
            let newState = {...prevState};
            valuePairList.forEach(pair => {
                newState[pair.name] = pair.value;
            });
            console.debug("STATE =>", newState);
            return newState;
        });
    };

    onNavBarUpdate = (settings) => {
        this.setState({
            systemSettings: settings
        });
        let requestList = [];
        let devicesToUpdate = [];
        this.state.devices.forEach((device, index) => {
            if (Object.values(device.groups).find(group => group.readyToDraw === true) !== undefined && device.isDrawn) {
                let request = {};
                request.systemSettings = settings;
                request.device = device;
                devicesToUpdate.push(index);
                requestList.push(request);
            }
        });
        Client.getMultipleCalculatedCurves(requestList, (dataList) => this.redrawMultipleCurves(dataList, [], this.state.devices, devicesToUpdate));
    };

    removeTransformerOnlyDevices = (transformerIndex) => {
        let devices = this.state.devices;

        for (let index = devices.length - 1; index >= 0 && index > transformerIndex; index--) {
            if (this.isTransformerOnlyDevice(devices[index])) {
                devices.splice(index, 1);
            }
        }

        this.setState({devices: devices});
    };

    isTransformerOnlyDevice = (device) => {
        return (device.id === 10 || device.id === 12);
    };

    reGraphOnTransformerDeletion = (transformerIndex) => {
        let requestList = [];
        let devices = this.state.devices.filter((_, index) => index !== transformerIndex);
        let devicesToUpdate = [];
        devices
            .forEach((device, index) => {
                if (index > (transformerIndex - 1) && Object.values(device.groups).find(group => group.readyToDraw === true) !== undefined) {
                    let request = {};
                    request.systemSettings = this.state.systemSettings;
                    request.device = device;
                    requestList.push(request);
                    devicesToUpdate.push(index)
                }
            });
        let updateList = [
            {name: "currentDevice", value: transformerIndex !== 0 ? transformerIndex - 1 : 0},
            {name: "devicePanelOpen", value: transformerIndex !== 0},
            {name: "confirmDialogOpen", value: false},
            {name: "transformerData", value: undefined},
            {name: "actionType", value: ""}
        ];
        Client.getMultipleCalculatedCurves(requestList, (dataList) => this.redrawMultipleCurves(dataList, updateList, devices, devicesToUpdate));
    };

    recalcOnReorder = (updateList, devices, start, end) => {
        let requests = [];
        let indices = [];
        for (let i = start; i <= end; i++) {
            const device = devices[i];
            if (Object.values(device.groups).find(group => group.readyToDraw === true) !== undefined) {
                requests.push({
                    systemSettings: this.state.systemSettings,
                    device: device,
                });
                indices.push(i);
            }
        }

        Client.getMultipleCalculatedCurves(requests, (data) => this.redrawMultipleCurves(data, updateList, devices, indices));
    };

    redrawMultipleCurves = (dataList, updateList, devices, devicesToUpdate) => {
        devices = [...Object.freeze(devices)];
        let i = 0;
        dataList.forEach(data => {
            let index = devicesToUpdate[i++];
            if (index !== undefined) {
                let device = {...devices[index]};
                let groups = {...Object.freeze(device.groups)};
                this.clearSeries(groups);
                data.curves.forEach(curve => {
                    let groupName = this.getBaseGroup(curve.name);
                    let group = {...groups[groupName]};
                    let series = {...group.series};

                    series[curve.name] = new Serie(curve.curve, curve.type);
                    group.series = series;
                    groups[groupName] = group;
                });

                device.groups = groups;
                devices[index] = device;

                if (device.id === this.transformerId) {
                    updateList.push({name: "transformerData", value: new TransformerData(device.components, index)});
                }
            }
        });
        updateList.push({name: "devices", value: devices});

        this.genericUpdate(updateList);
    };

    clearSeries = (groups) => {
        for (let groupName in groups) {
            if (groupName !== 'global') {
                let group = {...groups[groupName]};
                group.series = {};
                groups[groupName] = group;
            }
        }
    };

    deleteProject = () => {
        this.setState({
            systemSettings: new SystemSettings(),
            devicePanelOpen: "init",
            currentDevice: 0,
            filled: true,
            deviceOptions: [],
            devices: [],
            confirmDialogOpen: false,
            actionType: "",
            confirmLabel: "",
            dialogContent: "",
            transformerData: undefined
        });
        setTimeout(function () {
            Highcharts.charts.forEach(chart => chart?.reflow())
        }, 100);
    };
}