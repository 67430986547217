export const areAllInputsInRange = (index, device) =>{
    let outOfRangeList = [];
    let readyToDrawGroups = new Set();
    let groups = Object.entries(device.groups);
    let hasMultipleGroups = groups.length>1;

    for(const group of groups){
        if(group[1].readyToDraw === true || group[0] === "global")  readyToDrawGroups.add(group[0]);
    }

    let booleanComponents = device.components.filter(component => component.type==='BOOLEAN');
    let numericAndSelectionComponents = device.components.filter(component => (component.type==='NUMERIC_INPUT' ||component.type==='SELECT_ONE')
        && readyToDrawGroups.has(findComponentPath(component))
        && isComponentEnabledAndRestricted(component, booleanComponents));

    for(const component of numericAndSelectionComponents){
        if(component.type === 'SELECT_ONE' && (component.value === undefined || component.value.value === undefined)){
            outOfRangeList.push(hasMultipleGroups ? formatComponentWithGroup(component) : component.label);
        } else if(component.type === 'NUMERIC_INPUT' && !isNumericComponentValid(component, device.components)){
            outOfRangeList.push(hasMultipleGroups ? formatComponentWithGroup(component) : component.label);
        }
    }

    return outOfRangeList;
};

const findComponentPath = (component) =>{
    return component.groupPath.substring(0,component.groupPath.indexOf('/'));
};

const formatComponentWithGroup = (component) => {
    let name = findComponentPath(component);
    name = name.replace(/\${.*}/g, "-");

    // COOR-1547 -> 'global' is not meaningful to user but 'main' is. 'global' can be confused with system settings.
    if (name === "global") {
        name = "main";
    }
    if(name.includes('-')){
        let groupLabelParts = name.split('-');
        return groupLabelParts[0].charAt(0).toUpperCase() + groupLabelParts[0].slice(1) + '-' + groupLabelParts[1].charAt(0).toUpperCase() + groupLabelParts[1].slice(1) + " : " + component.label;
    }
    return name.charAt(0).toUpperCase() + name.slice(1) + " : " + component.label;
};

const isNumericComponentValid = (component, numericAndSelectionComponents) =>{
    if (component.value.value === undefined || component.value.value === "" || isNaN(component.value.value)) {
        return false;
    }
    if (component.min !== undefined) {
        const min = findValue(component.min, numericAndSelectionComponents);
        if (min !== undefined && parseFloat(component.value.value) < parseFloat(min)) {
            return false;
        }
    }
    if (component.max !== undefined) {
        const max = findValue(component.max, numericAndSelectionComponents);
        if (max !== undefined && parseFloat(component.value.value) > parseFloat(max)) {
            return false;
        }
    }

    return true;
};

const isComponentEnabledAndRestricted = (component, booleanComponents) => {
    let isEnabled = true;

    if (component.dependsOn !== undefined) {
        for (const c of booleanComponents) {
            if (c.groupPath === component.dependsOn && c.value.value === false) {
                isEnabled = false;
            }
        }
    }
    return isEnabled
};

const findValue = (optionalValue, componentList) => {
    if(optionalValue.source !== undefined){
        const component = componentList.find( it => it.groupPath === optionalValue.source);
        if (component !== undefined && checkIfEnabled(component, componentList)) {
            return component.value.value;
        } else {
            return optionalValue.default;
        }
    }else{
        return optionalValue.value;
    }
};

const checkIfEnabled = (component, componentList) => {
    const source = component.dependsOn;

    return source === undefined ? true : componentList.find( it => it.groupPath === source).value.value;
};
