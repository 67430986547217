export default class NumberUtil {
    validInputPattern = /^(-?)(\d*)(\.?)(\d{0,4})\d*$/;
    decimalPeriodPattern = /^-?(\.)\d*$/;


    isValidInput = (input) => {
        return this.validInputPattern.test(input);
    }

    processInput = (input) => {
        
        const vals = this.validInputPattern.exec(input);
        if (vals[2] !== ''){
            vals[2] = parseFloat(vals[2]).toPrecision().toString(); 
        }
        
        let value = `${vals[1]}${vals[2]}${vals[3]}${vals[4]}`;
        if (this.decimalPeriodPattern.test(value)){
            value = value.replace(/\./, '0.');
        }

        return value;
    }
}