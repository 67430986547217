import axios from "axios/index";
import fileSaver from "file-saver";

class ClientClass {
    basePath = process.env.REACT_APP_API_URL;
    defaultMessage = "Unable to complete your request at this time. Please try again later.";
    unknownError = "An unexpected error occurred. Please try again later.";
    emptyGraphMessage = "Info: ($curve-names) The configuration options provided were valid but do not result in a graph generated.";
    previousInfoMessage = null;
    
    initialize(growlRef) {
        this.growl = growlRef;
    }

    getDevices = (includeTransDevices, updater) => {
        let axiosCall = "";
        if(includeTransDevices == false){
            axiosCall = this.basePath + `devices`
        }
        else{
            axiosCall = this.basePath + `devices?hasTransformer=${includeTransDevices}`
        }
        axios.get(axiosCall)
            .then(response => { updater(response.data); })
            .catch(reason => {
                this.displayErrorMessage(reason.response === undefined ? this.defaultMessage : this.unknownError);
            })
    };

    getNextComponents = (request, updater) => {
        console.log('Making component call');
        axios.post(this.basePath + 'component', request)
            .then(response => { updater(response.data); })
            .catch(reason => {
                this.displayErrorMessage(reason.response === undefined ? this.defaultMessage : this.unknownError);
            })
    };

    getNextComponentsFromUpload = (request, updater) => {
        console.log('Making component call');
        axios.post(this.basePath + 'component', request)
            .then(response => { updater(response); })
            .catch(reason => {
                if (reason.response === undefined){
                    this.displayErrorMessage(this.defaultMessage);
                } else {
                    updater(reason.response);
                }
            })
    };

    formatName = (name) => {
        return name.replace("${slash}", "-");
    }

    getCalculatedCurves = (request, updater) => {
        axios.post(this.basePath + 'curve', request)
                .then(response => {
                    const emptyCurveNames = response.data.curves.reduce((names, curve) => {
                        if (curve.curve.length === 0) {
                            names.push(this.formatName(curve.name));
                        }
                        return names;
                    }, []);
                    if (emptyCurveNames.length > 0) {
                        this.displayInfoMessage(this.emptyGraphMessage.replace("$curve-names", emptyCurveNames.join(", ")));
                    } else {
                        this.removePreviousInfoMessage();
                    }
                    updater(response.data);
                    
                })
            .catch(reason => {
                this.displayErrorMessage(reason.response === undefined ? this.defaultMessage : this.unknownError);
            })
    };

    getMultipleCalculatedCurves = (request, updater) => {
        axios.post(this.basePath + 'curve/list', request)
            .then(response => {
                updater(response.data);
            })
            .catch(reason => {
                this.displayErrorMessage(reason.response === undefined ? this.defaultMessage : this.unknownError);
            })
    };

    getCsvExport = (request) => {
        axios.post(this.basePath + 'csv', request)
            .then(response => {
                const csvFile = new Blob([response.data], {type: "text/csv;charset=utf-8"} );
                fileSaver.saveAs(csvFile, request.device.name + '.csv')
            })
            .catch(reason => {
                this.displayErrorMessage(reason.response === undefined ? this.defaultMessage : this.unknownError);
            })
    };

    getXsptDownloadExport = (device, errorHandler) => {
        let components = {components: device.components}
        axios.post(this.basePath + 'setpoints/download', components)
            .then(response => {
                const xsptFile = new Blob([response.data], {type: "text/xml;charset=utf-8"})
                fileSaver.saveAs(xsptFile, device.name + this.generateTimestamp() + '.xspt')
            })
            .catch(error => {
                errorHandler(error)
            })

    };

    generatePdfExport = (exportSummary) => {

        let exportFilename = 'coordinaide_export' + this.generateTimestamp() + '.pdf';

        axios.post(this.basePath + 'export/pdf/' + exportFilename, exportSummary, {responseType: 'blob'})
            .then(response => {

                const fileContents = new Blob([response.data], {type: "application/pdf"})
                fileSaver.saveAs(fileContents, exportFilename)
            })
            .catch(error => {
                console.log("Error while generating the PDF export", error);
                this.displayErrorMessage("Unable to generate PDF export at this time. Please try again later.")
            })

    };

    getXsptUploadImport = (fileContents, sectionKey, updater, errorHandler) => {

        let sectionKeyParts = sectionKey.split("-");
        let profile = sectionKeyParts[0];
        let direction = sectionKeyParts[1];

        let axiosConfig = {
            headers: {
                'Content-Type' : 'application/xml'
            }
        };

        axios.post(this.basePath + `setpoints/upload/${profile}/${direction}`, fileContents, axiosConfig)
            .then(response => {
                updater(response.data)
            })
            .catch(error => {
                console.log ("XSPT - Conversion Error: " + error);
                errorHandler("xsptConversion", "conversionError");
            })
    };

    getXsptSectionOptions = (updater, errorHandler) => {
        axios.get(this.basePath + 'setpoints/upload/options')
            .then(response => { updater(response.data); })
            .catch(error => {
                errorHandler(error)
            })
    };

    saveState = (projectState) => {
        let body = (this.getData(projectState));
        const filename = this.generateFileName();
        axios.post(this.basePath + `save?filename=${filename}`, body)
            .then(response => {
                const file = new Blob([JSON.stringify(response.data)], {type: "text/plain;charset=utf-8"} );
                fileSaver.saveAs(file, filename)
            })
            .catch(error => {
                console.log("Unable to download project, please try again.", error);
                this.displayErrorMessage("Unable to download project, please try again.")
            })
    };

    verifyState = (request, updater) => {
        axios.post(this.basePath + 'verify', request)
            .then(response => updater(response))
            .catch(error => {
                console.log("Error while verifying uploaded file.", error);
                this.displayErrorMessage("Error while verifying uploaded file.")
            })
    };

    displayErrorMessage = (message) => {
        let errorNotification = this.growl.addErrorNotif(message);
        let removeNotification = this.growl.removeNotification;

        setTimeout(function () {
            removeNotification(errorNotification);
        }, 5000);
    };

    displayInfoMessage = (message) => {
        this.removePreviousInfoMessage();
        this.previousInfoMessage = this.growl.addRegularNoti(message, false, true);
    };

    removePreviousInfoMessage = () => {
        if (this.previousInfoMessage !== null) {
            this.growl.removeNotification(this.previousInfoMessage);
        }
    }

    getData = (projectState) => {
        return {systemSettings: projectState.systemSettings,
            devicePanelOpen: projectState.devicePanelOpen,
            currentDevice: projectState.currentDevice,
            deviceOptions: projectState.deviceOptions,
            devices: projectState.devices};
    };

    generateFileName = () => {

        return `coordinaide${this.generateTimestamp()}.coor`
    };

    generateTimestamp = () => {

        const date = new Date();
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0'+ hours : hours;
        minutes = minutes < 10 ? '0' + minutes: minutes;
        seconds = seconds < 10 ? '0' + seconds: seconds;

        return `${year}${month}${day}-${hours}${minutes}${seconds}`;

    };

    getSeriesToExport = (device) => {
        const groups = Object.keys(device.groups);
        let seriesList = [];

        for (let i = 0; i < groups.length; i++) {
            const curveData = Object.values(device.groups)[i];

            if (curveData.readyToDraw) {
                let data = Object.values(curveData.series)[0].data;
                seriesList.push({name : groups[i], curve : data});
            }
        }

        return seriesList;
    };

}

export const Client = new ClientClass();