import React from 'react'

export default class ReadOnlyField extends React.Component{

    render(){
        return(
            <div className={"readOnlyField " + this.props.className }>
                <span className="headerLabel">{this.props.label}</span>
                <span className="value">{this.props.default}</span> <span className="suffix">{this.props.suffix}</span>
            </div>
        )
    }
}