/**
 * Created by ameer.sami on 3/28/2018.
 */
import React from 'react'
import {Form, Radio} from 'semantic-ui-react'

const radioButtons = (props) => (
    <div className="radioGroup">
        <span>{props.label}</span>
        <Form>
            <Form.Field>
                <Radio
                    label={getLabel(props.option1)}
                    value={getValue(props.option1)}
                    checked={props.selected === getValue(props.option1)}
                    onChange={props.changed}
                    disabled = {props.disableRadioButtons}
                />
            </Form.Field>
            <Form.Field>
                <Radio
                    label={getLabel(props.option2)}
                    value={getValue(props.option2)}
                    checked={props.selected === getValue(props.option2)}
                    onChange={props.changed}
                    disabled = {props.disableRadioButtons}
                />
            </Form.Field>
        </Form>
    </div>
);

const getValue = (option) => {
    if (typeof option === "string") {
        return option;
    } else {
        return option.value;
    }
};

const getLabel = (option) => {
    if (typeof option === "string") {
        return option;
    } else if (option.text !== undefined){
        return option.text;
    } else {
        return option.value;
    }
};

export default radioButtons