import React from 'react'
import Graph from '../graph/graph'
import {Checkbox} from 'semantic-ui-react'

export default class tccGraph extends React.Component {
    state = {
        cycles : false,
        filled : true
    };

    yAxisChangeHandler = () => {
        this.setState({
            cycles : !this.state.cycles
        })
    };

    changeFilled = () => {
        this.setState({
            filled : !this.state.filled
        })
    };

    render() {
        return (
            <div className="tccContainer">
                <div className={"tccGraph " + (this.props.series.length < 4)}>
                    <Checkbox   label={"Time in Cycles"}
                                 checked={this.state.cycles}
                                 onChange={this.yAxisChangeHandler}

                    />
                    <Checkbox label="Curve Fills"
                              checked={this.state.filled}
                              onChange={this.changeFilled}/>
                    <Graph
                        series={this.props.series}
                        filled={this.state.filled}
                        annotations={this.props.annotations}
                        plotLine={this.props.systemFault}
                        frequency={this.props.frequency}
                        yAxis={this.state.cycles}
                        updater={this.props.updater}
                    />
                </div>
            </div>
        )
    };

};