import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import Icon from '../icon/icon'

export default class DeviceCard extends React.Component{
    render(){
        return[
            <Draggable key={this.props.index} draggableId={"" + this.props.index} index={this.props.index}>
               {(provided, snapshot) => (
                   <div onClick={this.props.onSelect(this.props.index)}
                       ref={provided.innerRef}
                       {...provided.draggableProps} title={this.props.name}>
                       <div className={"deviceCard " + (this.props.isSelected ? 'selected' : '')} onClick={this.props.onSelect(this.props.index)}>
                            <div className='deviceCardBody'>
                                {this.props.graphColor ?
                                    <div className="deviceColor" style={{background: this.props.graphColor, border: 'none'}}/>
                                    :
                                    <div className="deviceColor"/>
                                }
                                
                                <span className={this.props.hasWarning ? 'warningIcon' : ''}>{this.props.name.length > 45 ? this.props.name.substring(0, 42).concat("...") : this.props.name}</span>
                                    {this.props.hasWarning ?
                                        <div className="warningIcon">
                                            <Icon name="info"/>
                                        </div>
                                        :
                                        null
                                }
                                <div className="dotHandles" {...provided.dragHandleProps}/>
                            </div>
                        </div>
                        <div className="deviceConnector"/>
                        {provided.placeholder}
                    </div>
                )}
            </Draggable>
        ]
    }
}