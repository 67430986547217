import React from 'react'
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import DeviceCard from '../devicecard/deviceCard';
import LargeButton from '../buttons/largebutton/largeButton';
import Device from "../../ui/model/Device";
import Highcharts from "highcharts";
import { Client } from "../../ui/utils/Client";
import isEmpty from 'is-empty';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default class deviceChain extends React.Component {
    transformerId = 11;

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const origin = result.source.index;
        const destination = result.destination.index;

        if (!this.isReorderValidForTransformer(origin, destination, this.props.devices)) {
            let errorNotification = this.props.growl.current.addErrorNotif("Invalid move");
            let growl = this.props.growl.current;

            setTimeout(function () {
                growl.removeNotification(errorNotification);
            }, 5000);
            return;
        }

        const items = reorder(
            this.props.devices,
            origin,
            destination
        );

        const start = origin < destination ? origin : destination;
        const end = origin < destination ? destination : origin;

        let affected = items.slice(start, end + 1);
        const updateList = [
            {name: "devicePanelOpen", value: true},
            {name: "currentDevice", value: result.destination.index}
        ];

        if (affected.find(dev => dev.id === 11) !== undefined) {
            this.props.recalc(updateList, items, start, end)
        } else {
            updateList.push({name: "devices", value: items});
            this.props.update(updateList);
        }
    };

    isReorderValidForTransformer = (from, to, devices) => {
        // Make sure a transformer isn't being moved past a transformer-only device:
        if (devices[from].id === this.transformerId && from < to) {
            for (let index = from; index <= to; index++) {
                if (this.isTransformerOnlyDevice(devices[index])) {
                    return false;
                }
            }
        }
        // Make sure a transformer-only device isn't being moved before a transformer:
        else if (this.isTransformerOnlyDevice(devices[from]) && from > to) {
            for (let index = from; index >= to; index--) {
                if (devices[index].id === this.transformerId) {
                    return false;
                }
            }
        }
        return true;
    };

    isTransformerOnlyDevice = (device) => {
        return (device.id === 10 || device.id === 12);
    };

    onDeviceSelect = (index) => () => {
        this.props.update([
            {name: "devicePanelOpen", value: true},
            {name: "currentDevice", value: index}
        ]);
        setTimeout(function(){Highcharts.charts.forEach(chart => chart?.reflow())}, 1000);
    };

    onAddDeviceHandler = () => {
        if (!this.props.isSystemSettingsValid()) {
            return;
        }
        const index = this.props.devices.length;
        let newDevice = new Device('', '', false);
        let devices = this.props.devices.concat(Object.freeze(newDevice));
        let isTransformerPresent = this.isPlottedTransformerBeforeIndex(index);

        Client.getDevices(isTransformerPresent, (data) => {
            this.props.update([
                {name: "devicePanelOpen", value: true},
                {name: "currentDevice", value: index},
                {name: "deviceOptions", value: data},
                {name: "devices", value: devices}
            ]);
        });

        setTimeout(function(){Highcharts.charts.forEach(chart => chart?.reflow())}, 1000);
    };

    isPlottedTransformerBeforeIndex = (index) => {
        let transformerIndex = this.props.devices.findIndex(device => device.id === this.transformerId);

        if (transformerIndex >= 0 && transformerIndex < index && this.props.devices[transformerIndex].hasOwnProperty('groups')) {
            let groups = this.props.devices[transformerIndex]['groups'];

            if (groups.hasOwnProperty('transformer') && 
                    groups['transformer'].hasOwnProperty('series') && 
                        Object.keys(groups['transformer']['series']).length > 0) {
                return true;
            }
        }
        return false;
    };

    render() {
        return (
            <div className="deviceChainContainer">
                <div className="deviceChain">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                >

                                    {this.props.devices.length > 0 ?
                                        <div>
                                            {this.props.devices.map((device, index) =>
                                                <DeviceCard name={device.name}
                                                            graphColor={device.hasMultipleCurves || isEmpty(device.groups) ? undefined : Object.values(device.groups)[0].color}
                                                            isSelected={(this.props.current === index)} hasWarning ={(device.id === this.transformerId)} index={index} onSelect={this.onDeviceSelect}/>
                                            )}
                                            <LargeButton isOutLine={true} text="Add Device" iconName="addGray"
                                                         click={this.onAddDeviceHandler}/>
                                        </div>
                                        :
                                        <div>
                                            <LargeButton isOutLine={false} text="Add Device" iconName="addWhite"
                                                         click={this.onAddDeviceHandler}/>
                                            <LargeButton isOutLine={false} text="Upload Existing Project"
                                                         iconName="uploadWhite" click={this.props.uploadExistingProject}/>
                                        </div>

                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        )
    }
};