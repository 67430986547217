import React from 'react'
import { Button } from 'semantic-ui-react'
import Icon from "../../icon/icon"

const largeIconButton = (props) => {
    return[
        <div key = "largeButtonDiv" className={"largeIconButton " + (props.className || "")}>
            <Button fluid className={props.isOutLine ? 'outline' : 'solid'} disabled={props.disabled} onClick={props.click} style={props.style}>
                {props.iconName ?
                    <Icon name={props.iconName}/>
                    :
                    ''
                }
                {props.text}
            </Button>
        </div>
    ]
};


export default largeIconButton