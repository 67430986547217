import React from 'react'
import { Icon } from 'semantic-ui-react'

//Icons
import addGray from './icons/addicons/addIconGray.svg'
import addWhite from './icons/addicons/addIconWhite.svg'
import deleteDarkGray from './icons/deleteicons/deleteIconDarkGray.svg'
import deleteGray from './icons/deleteicons/deleteIconGray.svg'
import deleteWhite from './icons/deleteicons/deleteIconWhite.svg'
import deleteRed from './icons/deleteicons/deleteIconRed.svg'
import uploadWhite from './icons/uploadicons/uploadIconWhite.svg'
import uploadGray from './icons/uploadicons/uploadIconGray.svg'
import dotHandles from './icons/dotHandles.svg'
import downloadGray from './icons/downloadIcon.svg'
import leftArrow from './icons/leftArrow.svg'
import menuDots from './icons/menuDotsIcon.svg'
import dropdownArrow from './icons/dropdownArrow.svg'
import eyeOpen from './icons/eyeIcons/eyeOpenIcon.svg'
import eyeClosed from './icons/eyeIcons/eyeClosedIcon.svg'
import close from './icons/close.svg'
import loadingCircle from './icons/loadingicons/loadingCircle.svg'
import loadingCheck from './icons/loadingicons/loadingCheck.svg'
import info from './icons/info.svg'

const icon = (props) => {

    const styles = (() =>{
            switch(props.name){
                case "addGray":
                    return ".addGray{background-image: url(" + addGray.toString() + ");}" +
                            ".outline:hover .addGray{ background-image: url(" + addWhite.toString() + ");}";
                case 'addWhite':
                    return ".addWhite{background-image: url(" + addWhite.toString() + ");}";
                case 'deleteDarkGray':
                    return ".deleteDarkGray{background-image: url(" + deleteDarkGray.toString() + ");} " +
                            ".deleteDarkGray:hover{background-image: url(" + deleteRed.toString() + ");}";
                case 'deleteGray':
                    return ".deleteGray{background-image: url(" + deleteGray.toString() + ");}";
                case 'deleteWhite':
                    return ".deleteWhite{background-image: url(" + deleteWhite.toString() + ");}";
                case 'deleteRed':
                    return ".deleteRed{background-image: url(" + deleteRed.toString() + ");}";
                case 'uploadWhite':
                    return ".uploadWhite{background-image: url(" + uploadWhite.toString() + ");}";
                case 'uploadGray':
                    return ".uploadGray{background-image: url(" + uploadGray.toString() + ");}";
                case 'dotHandles':
                    return ".dotHandles{background-image: url(" + dotHandles.toString() + ");}";
                case 'downloadGray':
                    return ".downloadGray{background-image: url(" + downloadGray.toString() + ");}";
                case "leftArrow":
                    return ".leftArrow{background-image: url(" + leftArrow.toString() + ");}";
                case 'menuDots':
                    return ".menuDots{background-image: url(" + menuDots.toString() + ");}";
                case 'dropdownArrow':
                    return ".dropdownArrow{background-image: url(" + dropdownArrow.toString() + ");}";
                case 'eyeOpen':
                    return ".eyeOpen{background-image: url(" + eyeOpen.toString() + ");}";
                case 'eyeClosed':
                    return ".eyeClosed{background-image: url(" + eyeClosed.toString() + ");}";
                case 'close':
                    return ".close{background-image: url(" + close.toString() + ");}";
                case 'loadingCircle':
                    return ".loadingCircle{background-image: url(" + loadingCircle.toString() + ");}";
                case 'loadingCheck':
                    return ".loadingCheck{background-image: url(" + loadingCheck.toString() + ");}";
                case 'info':
                    return ".info{background-image: url(" + info.toString() + ");}";
                default:
                    return "";
            }
        }
    );

    return[
    <Icon key="iconKey" className={props.name}/>,
        <style key={props.name}>
            {styles() +
            ".warningRow:hover .deleteRed.icon{background-image: url(" + deleteWhite.toString() + ")} " +
            ".menuDots{background-image: url(" + menuDots.toString() + ");}" +
            ".dotHandles{background-image: url(" + dotHandles.toString() + ");}"}
        </style>
    ]
};

export default icon;