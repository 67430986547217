import React from 'react'
import NotificationSystem from 'react-notification-system'
import Icon from '../icon/icon'
import IconButton from '../buttons/iconbutton/iconButton'

export default class Growl extends React.Component{

    addNotification = (level, dismissible, children ) => {
        return this.growl.addNotification({
            message: '',
            level: level,
            autoDismiss: (dismissible ? 5 : 0),
            dismissible: (dismissible ? "both " : "none"),
            children: (
                children
            )
        });
    };

    addLoadingNoti = (text) => {
        const notification = this.addNotification("info", true, [
            <Icon name="loadingCircle" />,
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(notification)} />,
        ]);
        return notification;
    };

    addRegularNoti = (text, autoDismiss) => {
        const notification = this.addNotification("info", autoDismiss ?? true, [
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(notification)} />,
        ]);
        return notification;
    };

    addErrorNotif = (text) => {
        const notification = this.addNotification("error", true, [
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(notification)} />,
        ]);
        return notification;
    };

    addSuccessNotif = (text) => {
        const notification = this.addNotification("success", false, [
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(notification)} />,
        ]);
        return notification;
    };

    doneLoadingNotif = (existingNotiObj, text) => {
        this.tempNoti = existingNotiObj;
        this.tempNoti.children = [
            <Icon name="loadingCircle"/>,
            <Icon name="loadingCheck"/>,
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(existingNotiObj)} />,
        ];
        this.tempNoti.level = "success completed";

        this.growl.editNotification(existingNotiObj, this.tempNoti)
    };

    errorLoadingNotif = (existingNotiObj, text) => {
        this.tempNoti = existingNotiObj;
        this.tempNoti.children = [
            <span>{text}</span>,
            <IconButton iconName="close" onClick={() => this.growl.removeNotification(existingNotiObj)} />,
        ];
        this.tempNoti.level = "error";

        this.growl.editNotification(existingNotiObj, this.tempNoti)
    };

    editNotification = (oldNoti, newNoti) => {
        this.growl.editNotification(oldNoti, newNoti)
    };

    removeNotification = (noti) => {
        this.growl.removeNotification(noti)
    };

    render(){

        return(
            <NotificationSystem ref={growl => this.growl = growl}/>
        )
    }

}