import React from 'react'
import {Accordion, Grid} from 'semantic-ui-react'
import Icon from '../icon/icon'
import ColorPicker from '../colorpicker/colorPicker'
import ComponentParser from "../../ui/utils/ComponentParser";
import IconButton from '../buttons/iconbutton/iconButton';

const deviceConfigurationCard = (props) => {
    const parser = new ComponentParser(props.device, props.systemSettings, props.transformerData, props.growl, props.fileUploader);

    return (
        <div className="configurationGroup">
            {props.isCollapsable ? generateCollapsibleCard(props, parser) : generateSingleCurveCard(props, parser)}
        </div>
    )
};

const generateSingleCurveCard = (props, parser) => {
    return(
        <div>
            <div className="actions noAccordion">
                <IconButton iconName={props.group.visible ? "eyeOpen" : "eyeClosed" } onClick={props.toggle(props.name, "visible")} toolTip="Toggle Curve Visibility"/>
            </div>
            <div className="configurationCard">
                <Grid padded={"vertically"}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ColorPicker color={props.group.color} update={props.colorUpdate}/>
                        </Grid.Column>
                        {parser.renderComponents(props.componentRoot, props.update)}
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    )
};

const generateCollapsibleCard = (props, parser) => {
    return (
        <Accordion fluid>
            <Accordion.Title active={props.group.active} index={props.index} onClick={props.toggle(props.name, "active")}>
                <div className="accordionGraphColor" style={{background: props.group.color}}/>
                <span>{parseName(props.name) || "New Setpoint Group"}</span>
                <Icon name="dropdownArrow"/>
            </Accordion.Title>
            <div className="actions">
                <IconButton iconName={props.group.visible ? "eyeOpen" : "eyeClosed" } onClick={props.toggle(props.name, "visible")} toolTip="Toggle Curve Visibility"/>
                {props.isDeletable ? <IconButton iconName="deleteDarkGray"/> : ''}
            </div>
            <Accordion.Content active={props.group.active}>
                <div className="configurationCard">
                    <Grid padded={"vertically"}>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ColorPicker color={props.group.color} update={props.colorUpdate}/>
                            </Grid.Column>
                            {parser.renderComponents(props.componentRoot, props.update)}
                        </Grid.Row>
                    </Grid>
                </div>
            </Accordion.Content>
        </Accordion>
    )
};

const parseName = (name) => {
    name = name.replace(/\${.*}/g, "-");
    if(name.includes('-')){
        let groupLabelParts = name.split('-');
        return groupLabelParts[0].charAt(0).toUpperCase() + groupLabelParts[0].slice(1) + '-' + groupLabelParts[1].charAt(0).toUpperCase() + groupLabelParts[1].slice(1)
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
};

export default deviceConfigurationCard