import React from 'react'
import {Dropdown} from 'semantic-ui-react'
import DropdownIcon from '../icon/icons/dropdownArrow.svg';

export default class customDropdown extends React.Component{

    state= {
        isOpened: !!this.props.value
    };

    componentWillReceiveProps(newProps){
        if(newProps.value === undefined){
            this.setState({isOpened: false})
        }
        else{
            this.setState({isOpened: true})
        }
    }

    handleFocus = () => {
        this.setState({
            isOpened: true
        });
    };

    render(){
        return (
            <div className="has-float-label dropdown">
                <Dropdown key={this.props.id + "_" + (this.props.value ?? "")}
                          className={this.props.value ? "has-selected-item" : ""}
                          value={this.props.value}
                          options={this.props.options}
                          id={"" + this.props.id}
                          onFocus={this.handleFocus}
                          onChange={this.props.onSelect}
                          disabled={this.props.disabled}
                          fluid search selection scrolling closeOnChange/>
                <label htmlFor={"" + this.props.id} className={"" + this.state.isOpened}>{this.props.label}</label>
                <style>{".has-float-label.dropdown .selection.dropdown > .dropdown.icon{ background-image: url(" + DropdownIcon.toString() + ")}"}</style>
            </div>
        )
    }
};