export const generateSeries = (groups, filled) => {
    let result = [];
    Object.values(groups).forEach(group => {
        Object.values(group.series).forEach(serie => {
            let point = serie.data.find(point => point.length === 3 && point[2] !== 'min');
            if (point !== undefined) {
                point[2] = group.name;
            }
            result.push(
                serie.type.toLowerCase() !== 'scatter' ?
                    {
                        name: group.name,
                        type: serie.type.toLowerCase(),
                        data: serie.data,
                        enableMouseTracking: true,
                        color: (filled ? group.color.replace(/[\d]+\)$/g, '.25)') : "transparent"),
                        showInLegend: false,
                        keys: ['x', 'y', 'id'],
                        lineWidth: 2,
                        lineColor: group.color,
                        markers: {
                            enabled: false
                        },
                        visible: group.visible,
                        animation: {
                            duration: 1000
                        }
                    }
                    :
                    {
                        name: group.name,
                        type: serie.type.toLowerCase(),
                        data: serie.data,
                        color: group.color,
                        lineWidth: 0,
                        enableMouseTracking: true,
                        showInLegend: false,
                        keys: ['x', 'y', 'id'],
                        markers: {
                            radius: 2,
                            enabled: true
                        },
                        marker: {
                            symbol: 'circle'
                        },
                        visible: group.visible,
                        animation: {
                            duration: 1000
                        }
                    }
            )
        });
    });
    return result;
};


export const generateStockChart = (sysFault, isCycles, frequency) => {
    let xAxis = generateXAxis(sysFault);
    xAxis.min = 1;
    xAxis.max = 100000;
    let yAxis = generateYAxis(isCycles, frequency);
    yAxis.min = 0.01;
    yAxis.max = 1000;
    let chart = createChart();
    chart.xAxis = xAxis;
    chart.yAxis = yAxis;
    chart.series = [{name: "stock", data: []}];

    return chart;
};

export const generatePopulatedChart = (groups, plotLine, filled, isCycles, frequency, updater) => {
    let xAxis = generateXAxis(plotLine);
    xAxis.min = 1;
    xAxis.max = 100000;
    let yAxis = generateYAxis(isCycles, frequency);
    yAxis.min = 0.01;
    yAxis.max = 1000;
    let chart = createChart(updater);
    chart.xAxis = xAxis;
    chart.yAxis = yAxis;
    chart.series = generateSeries(groups, filled);

    return chart;
};

const generateXAxis = (sysFault) => {
    return {
        allowDecimals: true,
        alignTicks: false,
        type: 'logarithmic',
        tickInterval: 1,
        minorTickInterval: 0.1,
        title: {
            text: 'Current in Amperes'
        },
        labels: {
            formatter: function(){
                return this.value.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," );
            }
        },
        startOnTick: true,
        endOnTick: true,
        plotBands: [{
            from: sysFault,
            to: 100000,
            color: 'rgba(249,6,6,0.5)',
            zIndex: 5,
            label: {
                text: "Above Available Fault Current",
                rotation: 90,
                style: {
                    color: 'white',
                    fontWeight: 'bold'
                },
                verticalAlign: "middle"
            }
        }]
    }
};

const generateYAxis = (isCycles, frequency) => {
    const label = isCycles ? "Time in Cycles" : "Time in Seconds";
    return {
        allowDecimals: true,
        alignTicks: false,
        type: 'logarithmic',
        tickInterval: 1,
        minorTickInterval: 0.1,
        title: {
            text: label
        },
        labels: {
            formatter: function(){
                return isCycles ?

                        this.value >= 1000 ?
                        (this.value * frequency).toString().split( /(?=(?:\d{3})+(?:\.|$))/g )[0] + "K"
                        :
                        (this.value * frequency).toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )

                    :
                    this.value.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," );
            }
        },
        plotLines: [{
            value: 0,
            width: 1,
            color: '#808080'
        }]
    }
};


const createChart = (updater) => {
    return {
        title: {
            text: ' '
        },

        boost:{
            enabled: true
        },

        chart: {
            zoomType: 'xy',
            events: {
                load: function (event) {
                    event.target.reflow();
                }
            }
        },

        credits: {
            enabled: false
        },

        legend: {
            enabled: false,
        },

        exporting: {
            enabled: false,
            sourceWidth: 600,
            sourceHeight: 500
        },

        tooltip: {
            enabled: true,
            formatter: function () {
                let idx = this.series.color.lastIndexOf(',');
                let color = this.series.color.substring(0,idx) + ',1)';

                return `<span style="color:${color}">●</span><b>${this.series.name.replace(/\${.*}/g, "/")}</b><br/>` +
                `Amps: ${format(this.x)}<br/>Seconds: ${format(this.y)}`
            }
        },

        plotOptions: {
            column: {
                pointPlacement: 'between'
            },
            series: {
                events: {
                    click: function (event) {
                        const tmp = this.name.indexOf('.');
                        const index = parseInt(this.name.substring(0,tmp), 10) -1;
                        if(updater !== undefined){
                            updater(index);
                        }
                    }                 
                }
            },
            line: {
                stickyTracking: false
            }
        }
    }
};

const format = (number) => {
    return parseFloat(parseFloat(number).toFixed(4)).toPrecision()
};

export const generateAnnotations = (series, visible) => {
    return {
        id: 'Anonns',
        visible: visible,
        labelOptions: {
            formatter: function () {
                if (this.series.type.toLowerCase() !== 'scatter') {
                    return '<span style="color:' + this.series.graph.stroke + '">\u25CF</span>' + this.series.name.replace(/\${.*}/g, "/")
                }
                else {
                    return '<span style="color:' + this.color + '">\u25CF</span>' + this.series.name
                }
            },

            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            shadow: false,
            padding: 5,
            distance: 16,
            borderRadius: 4,
            allowOverlap: true,
            borderColor: '#979797',
            style: {
                color: '#333333',
                cursor: 'default',
                fontSize: '12px',
                whiteSpace: 'nowrap'
            }
        },
        labels: series.map(serie => ({point: serie.name, text: serie.name.split(".")[0]}))
    }
};