import randomColor from 'randomcolor';

export default class CurveGroup {
    constructor(groupName, readyToDraw, active){
        this.name = groupName;
        this.readyToDraw = readyToDraw;
        this.active = active;
        this.color = randomColor({
            format: 'rgba',
            luminosity: 'bright',
            alpha: 1
        });
        this.visible =  true;
        this.hidden = false;
        this.series = {};
    }
}