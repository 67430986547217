/**
 * Created by ameer.sami on 3/27/2018.
 */
import React, { forwardRef, useImperativeHandle } from "react";
import image from "../../ui/resources/SC_COLOR_LOGO.svg";
import InputField from '../inputfield/inputField';
import KebabMenu from '../kebabmenu/kebabMenu';
import { Client } from "../../ui/utils/Client";
import IconButton from '../buttons/iconbutton/iconButton';
import RadioButtons from '../radiobuttons/radioButtons'
import isEmpty from 'is-empty';
import NumberUtil from '../../ui/utils/NumberUtil';

const navBar = forwardRef((props, ref) => {
    const userGuideURL = 'https://www.sandc.com/globalassets/sac-electric/documents/public---documents/sales-manual-library---external-view/instruction-sheet-100-500.pdf';
    const numberUtil = new NumberUtil();

    const voltageRef = React.useRef();
    const systemFaultRef = React.useRef();


    useImperativeHandle(
        ref,
        () => ({
            areAllInputsInRange: () => {
                const outOfRangeFields = [];
                if (voltageRef.current.state.isError) {
                    outOfRangeFields.push("System Settings: Voltage");
                }
                if (systemFaultRef.current.state.isError) {
                    outOfRangeFields.push("System Settings: Available Fault Current");
                }
                return outOfRangeFields;
            },
        }),
        []
    );

    const frequencyChangeHandler = (event, { value }) => {
        props.settings.frequency = value;
        props.update(props.settings);
    };

    const atLeastOneDeviceDefined=()=>{
        return props.projectState.devices.length > 0;
    };

    const systemValuesChangeHandler = (event) => {
        let settings = {...props.settings};
        const input = event.target.value;
        if (!numberUtil.isValidInput(input)) return;

        settings[event.target.name] = numberUtil.processInput(input);
        props.update(settings)
    };

    const saveProject = () => {
        Client.saveState(props.projectState)
    };

    const handleKeyDown=(event)=>{
        if (event.keyCode === 13){
            let settings = {...props.settings};
            settings[event.target.name] = event.target.value;
            props.update(settings)
        }
    };

    const loadUserGuide =() => {
        window.open(userGuideURL, "_blank");
    };

    const deleteProject=()=>{
        props.delete(
            [
                {name: "dialogHeaderText", value:"Delete Current Project Confirmation"},
                {name: "dialogContent", value:["Are you sure you would like to delete the current project? Once this has been done you can not recover this project if it has not been saved."]},
                {name: "confirmLabel", value:"Delete"},
                {name: "cancelLabel", value:"Cancel"},
                {name: "actionType", value:"deleteProject"},
                {name: "confirmDialogOpen", value:true},
                {name: "dialogSize", value:"small"}

            ]
        );
    };

    const uploadProject=()=>{
        if (props.projectState !== undefined &&
                props.projectState.devices !== undefined &&
                    props.projectState.devices.length > 0) {
            props.load([
                {name: "dialogHeaderText", value:"Upload Existing Project Confirmation"},
                {name: "dialogContent", value:["Are you sure you would like to upload an existing project? This will delete the existing project and you will not be able to recover it if it has not been saved."]},
                {name: "confirmLabel", value:"Proceed"},
                {name: "cancelLabel", value:"Cancel"},
                {name: "actionType", value:"uploadProject"},
                {name: "confirmDialogOpen", value:true},
                {name: "dialogSize", value:"small"}
            ]);
        } else {
            props.initialUpload();
        }
    };

    const generatePdfExport = () => {

        let exportSummary = props.compileSummaryExport();

        if(isEmpty(exportSummary.tables) || isEmpty(exportSummary.chartOptions.series) ) {

            let message = "At least one device must be plotted and visible to export"
            let errorNotification = props.growl.current.addErrorNotif(message);
            let removeNotification = props.growl.current.removeNotification;

            setTimeout(function () {
                removeNotification(errorNotification);
            }, 7000);

            return;
        }

        Client.generatePdfExport(exportSummary)

    }

    return (
        <div className="navBar">
            <div className="logoSection">
                <img src={image} alt=""/>
                <span>Coordinaide<sup>TM</sup></span>
            </div>
            <div className="generalConfigurationSection">
                <div className="configuration-fields">

                    <RadioButtons name="frequency" label="Frequency"
                                option1={{text: props.settings.frequencyOpt1+"Hz", value: props.settings.frequencyOpt1}}
                                option2={{text: props.settings.frequencyOpt2+"Hz", value: props.settings.frequencyOpt2}}
                                selected={props.settings.frequency} changed={frequencyChangeHandler} disableRadioButtons={atLeastOneDeviceDefined()}/>
                    <InputField name="voltage" label="Voltage" type="number" id="voltageKV" ref={voltageRef}
                                value={props.settings.voltage}
                                changed={systemValuesChangeHandler}
                                onKeyDown={handleKeyDown}
                                disabled={atLeastOneDeviceDefined()}
                                min={navBar.DEFAULT_SYSTEM_SETTINGS.voltage.min}
                                max={navBar.DEFAULT_SYSTEM_SETTINGS.voltage.max}
                                details={`${navBar.DEFAULT_SYSTEM_SETTINGS.voltage.min} <= ${navBar.DEFAULT_SYSTEM_SETTINGS.voltage.max}`}
                                suffix="kV"
                                noEmpty
                                />
                    <InputField name="systemFault" label="Available Fault Current" type="number" ref={systemFaultRef}
                                value={props.settings.systemFault}
                                changed={systemValuesChangeHandler}
                                onKeyDown={handleKeyDown}
                                min={navBar.DEFAULT_SYSTEM_SETTINGS.systemFault.min}
                                max={navBar.DEFAULT_SYSTEM_SETTINGS.systemFault.max}
                                suffix="A"
                                details={`${navBar.DEFAULT_SYSTEM_SETTINGS.systemFault.min} <= ${navBar.DEFAULT_SYSTEM_SETTINGS.systemFault.max}`}
                                noEmpty

                                />
                </div>
                <div className="configuration-buttons">

                    <IconButton iconName="info" toolTip="User Guide" onClick={loadUserGuide}/>
                    <KebabMenu side="left" toolTip="Project Options"
                            dropOptions={[
                                {text: "Download Project PDF Summary", value: "4", iconName: "downloadGray",isEnabled: atLeastOneDeviceDefined(), onClick: generatePdfExport},
                                {text: "Download Current Project", value: "1", iconName: "downloadGray",isEnabled: atLeastOneDeviceDefined(), onClick: saveProject},
                                {text: "Upload Existing Project", value: "2", iconName: "uploadGray",isEnabled: true, onClick: uploadProject},
                                {text: "Delete Current Project", value: "3", iconName: "deleteRed",isEnabled: atLeastOneDeviceDefined(), onClick: deleteProject}]}/>
                </div>
            </div>

        </div>
    );
});

navBar.DEFAULT_SYSTEM_SETTINGS = {
    voltage: {
        min: 0,
        max: 765,
    },
    systemFault: {
        min: 10,
        max: 100000,
    },
};

export default navBar;
