import React from 'react';

export default class FileUploader extends React.Component{

    state = {
        uploadHandler: () => {},
        errorHandler: () => {},
        fileType: "*"
    }

    constructor(props){
        super(props);
        this.styles = {
            visibility: 'hidden',
            width: '0px',
            height: '0px',
            position: 'fixed'
        };
    }

    _setParams = async (handler, errorHandler, fileType) => {
        await this.setState({uploadHandler: handler, errorHandler: errorHandler, fileType: fileType});
        return;
    }

    upload = (handler=() => {}, errorHandler=() => {}, fileType="*") => {
        this._setParams(handler, errorHandler, fileType).then(() => this.input.click());
    }

    _handleUpload = function(uploadEvent){
        const file = uploadEvent.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsText(file)
        fileReader.onload = (e) => this.state.uploadHandler(e, file.name, file);
        fileReader.onabort = (e) => this.state.errorHandler(e);
        fileReader.onerror = (e) => this.state.errorHandler(e);
        this.input.value = null;
    }

    render(){
        return(
         <input ref={ref => this.input = ref} 
                type="file" 
                style={this.styles} 
                onChange={e => this._handleUpload(e)} accept={this.state.fileType}/>
        )
    }

}
