import React from 'react'
import Icon from '../icon/icon'

const warningToolTip = (props) => (
  <div className="deviceWarningGroup">
    <Icon name={props.iconName}/>
    <span>{props.message}</span>
  </div>
);

export default warningToolTip