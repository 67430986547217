
import React, {Component} from 'react';
import { Client } from '../../ui/utils/Client';
import Dropdown from '../dropdown/dropdown'
import LargeButton from '../../components/buttons/largebutton/largeButton'
import Dialog from '../../components/dialog/dialog'

export default class XsptUpload extends Component {
    state = {
        xsptSectionOptions: [],
        xsptSelectedOption: undefined,
        isOpen: this.props.isOpen
    };

    componentDidMount() {
        const self = this;

        Client.getXsptSectionOptions(

            (data) => {

                let sectionOptions = data.options.map(option => {
                    let key = option.profile + '-' + option.direction;
                    return {text: key, value : key}
                });

                self.setState({
                    xsptSectionOptions: sectionOptions
                });

            },
            (error) => {

                console.log("XSPT - Unable to retrieve XSPT file section options", error);
                this.generalErrorGrowl("Loading XSPT is not available at this time. Please try again later.");
                this.props.dialogHandler(null, "xsptErrorOptionsRetrieval");
            });

    }

    render() {

        return (
            <div>
                { this.state && this.state.xsptSectionOptions.length > 0 &&

                    <Dialog headerText="XSPT File Upload"
                            isOpened={this.state.isOpen}
                            update={this.props.dialogHandler}
                            size="small"
                            actionType={"xsptUpload"}
                            isClosable={true}
                            isDimming={true}
                            content={[
                                <Dropdown id="xspt_upload"
                                          label="Select the XSPT Section"
                                          options={this.state.xsptSectionOptions}
                                          value={this.state.xsptSelectedOption === undefined ? null : this.state.xsptSelectedOption.value}
                                          onSelect={this.onXsptSectionSelected}
                                />,
                                <LargeButton key="xspt_upload_button"
                                             isOutLine={false}
                                             text="Select the XSPT file to upload"
                                             iconName="uploadGray"
                                             disabled={this.state.xsptSelectedOption === undefined}
                                             click={this.loadAndParseXsptFile}/>
                            ]}/>

                }
            </div>
        )
    }

    onXsptSectionSelected = (event, data) => {
        this.setState({xsptSelectedOption: data});
    };


    loadAndParseXsptFile = () =>  {

        this.props.fileUploader.current.upload(
            (fileContents) => {
                this.props.dialogHandler(null, "xsptFileSelected");
                this.displayProcessingGrowl();
                Client.getXsptUploadImport(
                    fileContents.target.result.toString(),
                    this.state.xsptSelectedOption.value, 
                    (data) => {
                            this.props.componentUpdater(data);
                            this.doneUploadingGrowl();
                    },
                    (uploadEvent, errorType) => {
                        console.log("XSPT - Unable to import XSPT file: " + errorType + " - Event: " + uploadEvent)
                        this.errorUploadingGrowl("Unable to process the XSPT file provided. Check the file and try again");
                    }
                )
            }, 
            () => {this.errorUploadingGrowl("Unable to process the XSPT file provided.  Check the file and try again")},
            ".xspt");

    };

    displayProcessingGrowl = () => {
        this.loadingNoti = this.props.growl.current.addLoadingNoti("Processing XSPT");
    };

    doneUploadingGrowl = () => {
        this.props.growl.current.doneLoadingNotif(this.loadingNoti, "XSPT Upload Completed");
        let loadingNoti = this.loadingNoti;
        let removeNoti = this.props.growl.current.removeNotification;
        setTimeout(function () {
            removeNoti(loadingNoti);
        }, 5000);
    };

    errorUploadingGrowl = (text) => {
        this.props.growl.current.errorLoadingNotif(this.loadingNoti, text);
        let errorGrowl = this.loadingNoti;
        let removeNoti = this.props.growl.current.removeNotification;
        setTimeout(function () {
            removeNoti(errorGrowl);
        }, 5000);
    };

    generalErrorGrowl = (text) => {

        this.addGeneralErrorNoti(text)
        let removeNoti = this.props.growl.current.removeNotification;
        let generalErrorNoti = this.generalErrorNoti;
        setTimeout(function () {
            removeNoti(generalErrorNoti);
        }, 8000);
    };

    addGeneralErrorNoti = (text) => {
        this.generalErrorNoti = this.props.growl.current.addErrorNotif(text);
    }



}







