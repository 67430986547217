import React, {Component} from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import './App.less';
import Coordinaide from './ui/coordinaide/Coordinaide'
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";
import Error404Page from "./components/error404Page/Error404Page"

const App = () => {
  return(
    <BrowserRouter>
      <ErrorBoundary>
      <Switch>
        <Route exact path='/' component={Coordinaide}/>
        <Route path="*" component={Error404Page} />
      </Switch> 
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App;