import React from 'react';
import ReadOnlyField from '../readonlyfield/readOnlyField';
import IconButton from '../buttons/iconbutton/iconButton';

const uploadedFileField = (props) => {
    return(
      <div className={props.className || "uploadedFileField"}>
        <ReadOnlyField label={props.labelName || "Imported Data Points"} default={props.label}/>
        <IconButton iconName={"deleteDarkGray"} onClick={props.onDelete}/>
      </div>
    );
};

export default uploadedFileField;