import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import Icon from '../icon/icon'

export default class KebabMenu extends React.Component {
    render(){
        return[
                <Dropdown item icon="" className="menuDots" direction={this.props.side} title={this.props.toolTip} key={this.props.toolTip}>
                    <Dropdown.Menu>
                        {this.props.dropOptions.map(dropItem =>
                            <Dropdown.Item className={this.determineClassName(dropItem)}
                                           value={dropItem.value}
                                           key={dropItem.value}
                                           onClick={dropItem.onClick}>
                                <Icon name={dropItem.iconName}/>
                                <span className="text">{dropItem.text}</span>
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
        ];
    }

    determineClassName =(dropItem)=>{
        if(dropItem.isEnabled === false){
            return "disabled Item"
        }
        return (dropItem.iconName === "deleteRed") ? 'warningRow' : ''
    }

};