import React from 'react';
import { Button } from 'semantic-ui-react'
import { ChromePicker } from 'react-color';

export default class ColorPicker extends React.Component {

    state={
        displayColorPicker: false,
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    render() {
        return(
            <div className="colorPicker">
                <span>Graph Color</span>
                <Button onClick={this.handleClick} style={{background: this.props.color}}/>
                {this.state.displayColorPicker ?
                    <div className="colorPickerContainer" >
                        <div className="handleCloseDiv" onClick={ this.handleClick }/>
                        <ChromePicker
                            color={this.props.color} onBlur={this.handleClick}
                            onChangeComplete={ this.props.update }
                            disableAlpha={true}/>
                    </div>
                    :
                    null
                }
            </div>
        )
    };
}